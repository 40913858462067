import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import "./swagger-custom.css";
import { spec } from "./spec";
import Logo from "src/common/components/logo/logo";

const ApiDocs = () => {
    // Group endpoints by their tags
    const groupedEndpoints = {};

    // Initialize groups for each tag
    spec.tags.forEach((tag) => {
        groupedEndpoints[tag.name] = [];
    });

    // Group the paths by their tags
    Object.entries(spec.paths).forEach(([path, methods]) => {
        Object.entries(methods).forEach(([method, details]) => {
            if (details.tags) {
                details.tags.forEach((tag) => {
                    groupedEndpoints[tag].push({
                        path,
                        method,
                        summary: details.summary,
                    });
                });
            }
        });
    });

    return (
        <div className="w-full bg-white">
            <div className="api-header w-full border-b border-black/10">
                <div className="w-[1400px] mx-auto p-6 py-8">
                    <div className="flex gap-4 items-center  sticky top-0 bg-white ">
                        <Logo className={`md:w-40`} /> <div className="text-xs bg-lightblue100/50 rounded-full p-2 py-1  font-bold">BETA</div>
                    </div>
                </div>
            </div>
            <div className="api-docs-container">
                <div className="api-sidebar">
                    <div className="p-6">
                        <nav>
                            <div className="text-[14px] font-semibold mb-10">API Reference</div>
                            {spec.tags.map((tag) => (
                                <div key={tag.name} className="api-sidebar-section flex flex-col gap-2">
                                    <div className="text-[12px] pl-3">{tag.name}</div>
                                    <ul>
                                        {groupedEndpoints[tag.name].map((endpoint) => (
                                            <li key={`${endpoint.path}-${endpoint.method}`}>
                                                <a
                                                    href={`#/${tag.name}/${endpoint.method}${endpoint.path.split("/").join("_")}`}
                                                    className="flex flex-row gap-2 items-start justify-start"
                                                    onClick={(e) => {
                                                        // Small delay to allow Swagger UI to process the URL change
                                                        setTimeout(() => {
                                                            const selector = `.opblock-${endpoint.method} [data-path="${endpoint.path}"]`;
                                                            const element = document.querySelector(selector);

                                                            if (element) {
                                                                // Find the parent opblock element
                                                                const opBlock = element.closest(".opblock");

                                                                // First scroll into view
                                                                element.scrollIntoView({
                                                                    behavior: "smooth",
                                                                });

                                                                const mainSection = element.closest(".opblock-tag-section");

                                                                if (mainSection) {
                                                                    const mainButton = mainSection.querySelector(".opblock-tag .expand-operation");
                                                                    if (mainButton && !mainSection.classList.contains("is-open")) {
                                                                        mainButton.click();
                                                                    }
                                                                }

                                                                if (opBlock) {
                                                                    const expandButton = opBlock.querySelector(".opblock-summary-control");
                                                                    if (expandButton) {
                                                                        // Force the section to close first
                                                                        if (opBlock.classList.contains("is-open")) {
                                                                            expandButton.click();
                                                                        }
                                                                        // Then open it
                                                                        setTimeout(() => {
                                                                            expandButton.click();
                                                                        }, 50);
                                                                    }
                                                                }
                                                            }
                                                        }, 100);
                                                    }}
                                                >
                                                    <span className={`method ${endpoint.method} !font-bold`}>{endpoint.method.toUpperCase()}</span>
                                                    <span className="endpoint-summary text-[12px] ">{endpoint.summary}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </nav>
                    </div>
                </div>
                <div className="api-content">
                    <SwaggerUI
                        spec={spec}
                        docExpansion="list"
                        deepLinking={true}
                        defaultModelsExpandDepth={-1}
                        displayOperationId={false}
                        filter={false}
                        showExtensions={true}
                        showCommonExtensions={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default ApiDocs;
