import { useEffect, useState } from "react";
import OnBottom from "src/common/components/on-bottom/on-bottom";
import Popup from "src/common/components/popup/popup";
import { upload } from "src/common/services/utils";
import artist1 from "src/common/assets/vids/artist1.mp4";
import { getAvatars, getAvatarVoices } from "src/common/services/avatars";
import { useNavigate } from "react-router-dom";
import { PiFolderUser } from "react-icons/pi";

const DirectoryAvatars = (props) => {
    const navigate = useNavigate();
    const { showCreatePopup, setShowCreatePopup } = props;
    const { selectedDirectory, setSelectedDirectory } = props;
    const [fetching, setFetching] = useState(false);
    const [directories, setDirectories] = useState([]);

    useEffect(() => {
        handleAvatars({ opr: "list" });
    }, []);

    const handleAvatars = async ({ opr, file, pageNum, id, avatardir }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                try {
                    const list_res = await getAvatars();
                    console.log("list_res ::", list_res);

                    // const voices_res = await getAvatarVoices();
                    // console.log("voices_res ::", voices_res);

                    //   setDirectories([...directories, ...list_res]);
                    setDirectories([...list_res]);
                } catch (error) {
                    console.log("error ::", error);
                }

                break;

            case "get":
                // try {
                //     const get_res = await getInputVideo({ id });
                //     //console.log(`get_res ::`, get_res);
                //     setSelVideo({ ...get_res[0] });
                // } catch (error) {
                //     //console.log("error ::", error);
                // }

                break;

            case "create":
                setFetching(true);
                const defaultFile = new File([artist1], "default_avatar.mp4", {
                    type: "video/mp4",
                });
                try {
                    const upload_res = await upload({ file: defaultFile, directory: `_video/${avatarName}` });
                    //console.log("upload_res ::", upload_res);
                    await handleAvatars({ opr: "list" });
                    if (props.OnUploaded) {
                        props.OnUploaded(upload_res);
                    }
                } catch (error) {
                    //console.log(error);
                    if (props.OnError) {
                        props.OnError(error);
                    }
                }
                setShowCreatePopup(false);
                setFetching(false);
                break;
            default:
                break;
        }

        setFetching(false);
    };

    const [avatarName, setAvatarName] = useState("");
    return (
        <div className="min-h-full h-full w-full relative slideFromTop flex-1">
            {showCreatePopup && (
                <Popup
                    OnHide={() => {
                        setShowCreatePopup(false);
                    }}
                >
                    <div className="flex flex-col">
                        <div className="flex flex-col gap-6 p-10">
                            <div className="text-lg font-semibold">Add Avatar</div>
                            <div className="flex flex-col border border-lightblue100 rounded-xl ">
                                <input
                                    type="text"
                                    className="w-full"
                                    placeholder="Avatar Name"
                                    value={avatarName}
                                    onChange={(e) => setAvatarName(e.target.value)}
                                />
                            </div>
                            <div className="text-sm text-black/50">Add a new avatar to your collection</div>
                            <button className="button text-white px-5 py-2 rounded-xl" onClick={() => handleAvatars({ opr: "create" })}>
                                Add Avatar
                            </button>
                        </div>
                    </div>
                </Popup>
            )}
            {/* {fetching && pageNum === 1 && <Preloader />} */}
            <OnBottom
                className={`flex-1`}
                OnBottom={async (val) => {
                    // if (pageNum > 1 && props.paging !== "manual") {
                    //     await handleVideos({ opr: "list", pageNum });
                    // }
                }}
            >
                {directories.length > 0 && (
                    <div className={`grid grid-cols-1 gap-6 justify-between ${props.cols ? `grid-cols-${props.cols}` : `grid-cols-5`}`}>
                        {directories.map((directory, v) => {
                            const parsedDirectory = directory.directory.replace("_video/", "");
                            return (
                                <div
                                    key={v}
                                    className="bg-white border-4 border-lightblue100/50 rounded-3xl relative overflow-hidden hover:border-lightblue100/80 cursor-pointer"
                                    onClick={() => {
                                        navigate(`/admin/avatars/${parsedDirectory}`);
                                    }}
                                >
                                    <div className="flex p-5 gap-5 items-center ">
                                        <div className="flex flex-row gap-5 relative overflow-hidden bg-lightblue100/50 rounded-full w-[50px] h-[50px] items-center justify-center">
                                            {/* {directory.images.map((image, i) => (
                                            <div className="aspect-square w-40 h-40 relative overflow-hidden rounded-2xl mb-5">
                                                <img src={image} alt={directory.name} className="w-full h-full object-cover" />
                                            </div>
                                        ))} 
                                            <div className="absolute top-0 right-0 w-3/4 h-full bg-gradient-to-l from-white/100 to-transparent"></div>
                                        
                                        */}
                                            <PiFolderUser  className="text-blue w-8 h-8" />
                                        </div>
                                        <div className="text-lg font-semibold leading-snug  ">{parsedDirectory}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}

                {!fetching && props.paging === "manual" && directories?.length > 10 && (
                    <div
                        className=" transition p-3 cursor-pointer mt-6 flex items-center justify-center border border-lightblue100 text-black/50 rounded-xl hover:text-black/100 hover:bg-lightblue100/30"
                        onClick={async () => {
                            // await handleVideos({ opr: "list", pageNum });
                        }}
                    >
                        Load more
                    </div>
                )}
            </OnBottom>
        </div>
    );
};

export default DirectoryAvatars;
