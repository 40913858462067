import { useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "src/layouts/admin-layout";
import { useState } from "react";
import ListGen from "src/modules/admin/generated-videos/list-gen";
import { Link } from "react-router-dom";

const HomeAdmin = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [action, setAction] = useState();
    const [selectedDirectory, setSelectedDirectory] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    return (
        <>
            <AdminLayout
                header={
                    <div className="flex flex-1 flex-col ">
                        <div className="flex flex-1 flex-row px-0 items-center">
                            <div className="flex items-center gap-5">
                                <div className="text-lg md:text-xl font-semibold flex flex-row items-center gap-4">Home</div>
                            </div>
                        </div>
                    </div>
                }
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full gap-20">
                    <div className="flex flex-1 flex-col gap-5">
                        <div className="flex flex-1 flex-row items-center justify-between">
                            <div className="text-lg md:text-lg font-semibold">Hot Videos</div>
                            <div>
                                <Link to="/admin/hot">View All</Link>
                            </div>
                        </div>
                        <div className="flex min-h-[400px] relative">
                            <ListGen vtype={`public`} coltype={`flex`} />
                        </div>
                    </div>

                    <div className="flex flex-1 flex-col gap-5">
                        <div className="flex flex-1 flex-row items-center justify-between">
                            <div className="text-lg md:text-lg font-semibold">Generated Videos</div>
                            <div>
                                <Link to="/admin/generated-videos">View All</Link>
                            </div>
                        </div>
                        <div className="flex min-h-[400px] relative">
                            <ListGen coltype={`flex`} />
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default HomeAdmin;
