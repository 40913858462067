import { useState, useEffect } from "react";
import axios from "axios";
import endpoint from "src/common/services/endpoints";

const GenerateMessage = (props) => {
    const { setShowGenerateMessage, messageObj, setMessageObj } = props;
    const [message, setMessage] = useState("");
    const [fetching, setFetching] = useState(false);

    const handleSendMessage = async () => {
        if (message === "") return;
        setFetching(true);
        try {
            const prompt_res = await axios.post(
                endpoint.rest + "santa/prompt",
                {
                    description: message,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log("prompt_res ::", prompt_res);
            if (prompt_res.data.result[0].response) {
                setMessageObj({ ...messageObj, text: prompt_res.data.result[0].response });
                setShowGenerateMessage(false);
            }
        } catch (error) {
            console.log("error ::", error);
        } finally {
            setFetching(false);
        }
    };

    return (
        <div className="flex flex-col gap-4">
            {fetching && (
                <div className="absolute left-0 top-0 w-full h-full bg-red-700/50 backdrop-blur-md z-50 flex flex-col rounded-4xl items-center justify-center">
                    <div className="text-[16px] roboto-slab relative z-20 text-center text-white leading-[24px] pt-6 pb-3 loading">
                        Santa is writing your message
                    </div>
                </div>
            )}
            <div className="text-white p-6 bg-red-800/50 border-red-800/80 border rounded-2xl">
                <textarea
                    value={message}
                    type="text"
                    placeholder="Tell santa what you want your message to be..."
                    className="w-full !p-0 placeholder:text-white/70 text-[14px] leading-[20px] min-h-[100px]"
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>

            <button
                disabled={message === "" || fetching}
                className={`bg-gradient-to-t from-teal-600 to-teal-500 text-white px-4 py-0 rounded-2xl text-[14px] transition-all  outline-none ${
                    fetching || message === "" ? "opacity-50 cursor-not-allowed" : "hover:translate-y-[2px] "
                }`}
                onClick={handleSendMessage}
            >
                Send to santa
            </button>

            <div
                className="text-[12px] roboto-slab relative z-20 text-center text-white cursor-pointer leading-[20px]"
                onClick={() => setShowGenerateMessage(false)}
            >
                Cancel
            </div>
        </div>
    );
};

export default GenerateMessage;
