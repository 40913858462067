import { useEffect, useState } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteVideo } from "src/common/services/generate";
import { deleteInputImage, deleteInputVideo } from "src/common/services/input";
import { deleteVoice } from "src/common/services/voices";
import { deleteProject } from "src/common/services/project";
import { deleteAvatarVideo } from "src/common/services/avatars";
const UiDelete = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { data } = props;
    const [label, setLabel] = useState(data?.TYPE);

    useEffect(() => {
        //console.log("data :: ", data);
        if (data?.TYPE === "gen") {
            setLabel("video");
        }
    }, [data]);

    const handleDelete = async () => {
        try {
            switch (data.TYPE) {
                case "gen":
                    await deleteVideo({ videoid: data.id });

                    break;

                case "video":
                    await deleteInputVideo({ videoid: data.id });
                    break;

                case "image":
                    await deleteInputImage({ imageid: data.id });
                    break;
                case "voice":
                    await deleteVoice({ voiceid: data.voiceid });
                    break;

                case "project":
                    await deleteProject({ id: data.id });
                    break;

                case "avatar-video":
                    await deleteAvatarVideo({ id: data.id });
                    break;

                default:
                    break;
            }
            props.OnClose(true);
            navigate(location.pathname, { state: { reload: true } });
        } catch (error) {
            //console.log("error :: ", error);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center p-10 gap-5">
            {/* <div className="aspect-square rounded-full w-32 h-32 flex items-center justify-center bg-rose-500/10"><FaRegTrashCan className="text-rose-500 text-3xl"  /></div> */}
            <div className="font-semibold text-xl">
                Delete <span className="capitalize">{label}</span>?
            </div>
            <div className="text-center opacity-60"> You are about to delete this {label}? This action cannot be undone .</div>

            {data?.TYPE === "campaign" && (
                <div className="min-w-full flex-1 flex flex-col items-center justify-center bg-rose-500/10 p-10 border border-dashed border-rose-500 rounded-2xl gap-5">
                    <div className="text-xl">{data?.name}</div>
                    {/* <div className="text-xs">...{data?.result?.slice(-30)}</div> */}
                </div>
            )}
            {data?.TYPE === "gen" && (
                <div className="min-w-full flex-1 flex flex-col items-center justify-center bg-rose-500/10 p-10 border border-dashed border-rose-500 rounded-2xl gap-5">
                    {data.result !== "" && (
                        <div className="aspect-squre h-32 w-32">
                            <img src={data.result + `/frame1.jpg`} className="bg-black rounded-xl w-full h-full object-cover" />
                        </div>
                    )}
                    <div className="text-xs">{data?.name}</div>
                </div>
            )}

            {data?.TYPE === "video" && (
                <div className="min-w-full flex-1 flex flex-col items-center justify-center bg-rose-500/10 p-10 border border-dashed border-rose-500 rounded-2xl gap-5">
                    <div className="aspect-squre h-32 w-32">
                        <img src={data?.url + `/frame1.jpg`} className="bg-black rounded-xl w-full h-full object-cover" />
                    </div>
                    <div className="text-xs">...{data?.url?.slice(-30)}</div>
                </div>
            )}

            {data?.TYPE === "image" && (
                <div className="min-w-full flex-1 flex flex-col items-center justify-center bg-rose-500/10 p-10 border border-dashed border-rose-500 rounded-2xl gap-5">
                    <div className="aspect-squre h-32 w-32">
                        <img src={data?.url} className="bg-black rounded-xl w-full h-full object-cover" />
                    </div>
                    <div className="text-xs">...{data?.url?.slice(-30)}</div>
                </div>
            )}

            {data?.TYPE === "voice" && (
                <div className="min-w-full flex-1 flex flex-col items-center justify-center bg-rose-500/10 p-10 border border-dashed border-rose-500 rounded-2xl gap-5">
                    <div className="aspect-squre h-32 w-32">
                        <img src={data?.sample + `/frame1.jpg`} className="bg-black rounded-xl w-full h-full object-cover" />
                    </div>
                    <div className="text-xs">...{data?.sample?.slice(-30)}</div>
                </div>
            )}

            <div className="min-w-full flex items-center justify-between mt-10">
                <div
                    className="button button-gray"
                    onClick={() => {
                        props.OnClose(true);
                    }}
                >
                    Cancel
                </div>

                <div
                    className="button bg-rose-500"
                    onClick={() => {
                        handleDelete();
                    }}
                >
                    Delete
                </div>
            </div>
        </div>
    );
};

export default UiDelete;
