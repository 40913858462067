import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleErr, handleRes } from "src/common/services/response";



////PROJECTS

export const listProjects = async ({ pageNum }) => {
    const token = getStoredUser()?.token;
    pageNum = pageNum || 1;
    const config = {
        method: "get",
        url: `${endpoint.rest}project/all?orderby=createdate desc&pagesize=20&pagenumber=${pageNum}`,
        headers: {  
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};


export const createProject = async (input) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}project/create`,
        data: input,
        headers: {  
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
}   


export const updateProject = async ({id,input}) => {
    
    // Convert all step fields to strings
    const stringifiedInput = Object.fromEntries(
        Object.entries(input).map(([key, value]) => [key, String(value)])
    );
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}project/${id}`,
        data: stringifiedInput,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };                                      
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
}

export const deleteProject = async ({id}) => { 
    const token = getStoredUser()?.token;
    const config = {
        method: "delete",
        url: `${endpoint.rest}project/${id}`,    
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
}


export const getProject = async ({ id }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "get",
        url: `${endpoint.rest}project/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
}








