import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLatentSync, getVideo } from "src/common/services/generate";
import { getInputVideo } from "src/common/services/input";
import Timeline from "src/common/components/timeline/timeline";
import TimelineNavi from "src/common/components/timeline/timeline-navi";
import AdminLayout from "src/layouts/admin-layout";
import Iicon from "src/common/components/iicon/iicon";
import Preloader from "src/common/components/preloader/preloader";
import AddInfo from "src/modules/admin/generated-videos/generate-videoOLD/add-info";
import AddVideo from "src/modules/admin/generated-videos/generate-videoOLD/add-video";

import { upload } from "src/common/services/utils";
import { generateVideo } from "src/common/services/generate";
import AddText from "src/modules/admin/generated-videos/generate-video/add-text";
import VideoPlayer from "src/common/components/video-player/video-player";
import { getTitleFromUrl } from "src/common/utils/utils";
import { getAvatarVideo } from "src/common/services/avatars";
import { runLatentSync } from "src/common/services/generate";
import { generateAvatar } from "src/common/services/avatars";
import { MdOutlineRecordVoiceOver } from "react-icons/md";

import Dbox from "src/common/components/dbox/dbox";
import Popup from "src/common/components/popup/popup";
import ListVoices from "src/modules/admin/voices/list-voices";
import CardAudio from "src/modules/admin/voices/card-audio";
import UseLLM from "src/modules/admin/generated-videos/generate-video/use-llm";

const GenerateVideo = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [fetching, setFetching] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const regexName = /[^a-zA-Z0-9_\s]/g;
    const timelineData = [
        // {
        //     id: "details",
        //     name: "Video Details",
        // },
        {
            id: "add-video",
            name: "Select Video / Image",
        },
        {
            id: "add-voice",
            name: "Select Voice",
        },
        {
            id: "add-text",
            name: "Create Script",
        },
    ];

    const [timeline, setTimeline] = useState(timelineData);

    const [voiceTab, setVoiceTab] = useState(null);
    const [selectedTimeline, setSelectedTimeline] = useState(timeline[0]);
    const [canNext, setCanNext] = useState(false);
    const [genData, setGenData] = useState({ name: "" });
    const [latentResultVid, setLatentResultVid] = useState(null);
    const [latentLoading, setLatentLoading] = useState(false);
    const [voicesPopup, setVoicesPopup] = useState(false);
    const [selectedVoice, setSelectedVoice] = useState(null);
    const [useLLM, setUseLLM] = useState(false);

    useEffect(() => {
        console.log("genData ::", genData);
    }, [genData]);

    useEffect(() => {
        console.log("params ::", params);
        if (params.campaignid) {
            //handleGenerate({ opr: "select", id: params.campaignid });
        }

        if (location.pathname.includes("duplicate-video")) {
            if (params.id && !genData.id) {
                getVideo({ id: params.id }).then((res) => {
                    console.log("res ::", res);
                    setGenData({ ...res[0] });
                });
            }
        } else if (location.pathname.includes("generate-video")) {
            if (params.id && !genData.id) {
                // getInputVideo({ id: params.id }).then((res) => {
                //     console.log("res ::", res);
                //     setGenData({ ...res[0] });
                // });

                getAvatarVideo({ id: params.id }).then((res) => {
                    console.log("res ::", res);
                    const gen_data = { ...res[0] };
                    gen_data.name = getTitleFromUrl(gen_data.url);
                    setGenData({ ...gen_data });
                });
            }
        }
        console.log("location ::", location);
    }, [params, location]);

    useEffect(() => {
        const todo = location?.state?.todo;
        const data = location?.state?.data;
        //console.log("todo ::", todo, data);
        switch (todo) {
            case "create":
                setSelectedTimeline(timeline[0]);
                setGenData({ name: "", ...data });

                break;

            case "regen":
                // data.name = `${data.name}`;
                setGenData({ ...data });
                setSelectedTimeline(timeline[timeline.length - 1]);

                break;
            default:
                break;
        }
    }, [location]);

    useEffect(() => {
        if (voiceTab === "direct") {
            const directTl = timelineData.filter((tl) => tl.id !== "add-text");
            setTimeline(directTl);
        } else {
            setTimeline(timelineData);
        }
    }, [voiceTab]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleGenerateOLD = async () => {
        setFetching(true);
        // //console.log("genData :: ", genData);
        // imageurl, videourl, audiourl, imageid, videoid, voiceid, voicegender
        const gen_input = { ...genData };
        gen_input.name = gen_input.name + " Copy";

        if (gen_input.file) {
            try {
                const file_res = await upload({ file: gen_input.file });
                //console.log("file_res :: ", file_res);

                if (gen_input.file.type.includes("video")) {
                    gen_input.videourl = file_res[0].cache;
                } else if (gen_input.file.type.includes("image")) {
                    gen_input.imageurl = file_res[0].cache;
                }
                delete gen_input.file;
            } catch (error) {
                //console.log(error);
            }
        }

        if (gen_input.audio) {
            try {
                const audio_res = await upload({ file: gen_input.audio });
                //console.log("audio_res :: ", audio_res);

                delete gen_input.audio;
                gen_input.audiourl = audio_res[0].cache;
            } catch (error) {
                //console.log(error);
            }
        }

        if (gen_input.videourl || gen_input.videoid) {
            gen_input.type = "video";
        } else if (gen_input.imageurl || gen_input.imageid) {
            gen_input.type = "image";
        }

        delete gen_input.URL;
        try {
            //console.log("gen_input (input) ::", gen_input);
            const gen_res = await generateVideo(gen_input);
            //console.log("gen_res (output) ::", gen_res);

            navigate("/admin/generated-videos", { state: { todo: "coin-update" } });
        } catch (error) {
            //console.log(error);
        }
        setFetching(false);
        // setFetching(false);
        //  props.OnSuccess(true);
    };

    const handleGenerate = async () => {
        setFetching(true);
        try {
            const input = {
                name: genData.name,
                videoid: genData.id,
                voiceid: genData.voiceid,
                prompt: genData.text,
            };
            console.log("input ::", input);

            const generate_res = await generateAvatar(input);
            console.log("generate_res ::", generate_res);
            navigate("/admin/generated-videos", { state: { todo: "coin-update" } });
        } catch (error) {
            console.log(error);
        }
        setFetching(false);
    };

    const generateLatentSync = async () => {
        setLatentLoading(true);
        try {
            const latent_input = {
                videourl: genData.url,
                audiourl: genData.audiourl,
            };
            console.log("latent_input ::", latent_input);
            const latent_res = await runLatentSync(latent_input);
            console.log("latent_res ::", latent_res);

            await pollLatentSync(latent_res[0].id);
        } catch (error) {
            console.log(error);
            setLatentLoading(false);
        }
    };

    const pollLatentSync = async (id) => {
        const MAX_ATTEMPTS = 120; // 10 minutes with 5-second intervals
        const INTERVAL = 5000; // 5 seconds
        let attempts = 0;

        const poll = async () => {
            try {
                const get_latent_res = await getLatentSync({ id });
                console.log("get_latent_res ::", get_latent_res);

                // Add status check - adjust the condition based on your API response
                if (get_latent_res[0].state === "complete") {
                    setLatentResultVid(get_latent_res[0].video);
                    setLatentLoading(false);
                    return get_latent_res;
                }

                attempts++;
                if (attempts >= MAX_ATTEMPTS) {
                    throw new Error("Polling timeout: Maximum attempts reached");
                }

                await new Promise((resolve) => setTimeout(resolve, INTERVAL));
                return poll();
            } catch (error) {
                console.log(error);
                throw error;
            }
        };

        return poll();
    };

    const canSubmit = () => {
        if (genData.name !== "" && genData.voiceid && genData.voiceid !== "" && genData.text !== "") {
            // switch (voiceTab) {
            //     case "direct":
            //         if (
            //             (genData.file || genData.videoid || genData.videourl || genData.imageid || genData.imageurl) &&
            //             (genData.audio || genData.voiceid || genData.audiourl)
            //         ) {
            //             return true;
            //         }
            //         break;

            //     case "default":
            //         if ((genData.text !== "" && genData.file) || genData.videoid || genData.videourl || genData.imageid || genData.imageurl) {
            //             return true;
            //         }
            //         break;

            //     case "fromId":
            //         if (
            //             genData.text !== "" &&
            //             (genData.file || genData.videoid || genData.videourl || genData.imageid || genData.imageurl) &&
            //             genData.voiceid
            //         ) {
            //             return true;
            //         }
            //         break;
            //     default:
            //         return false;
            // }
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <AdminLayout type="full" className={`gap-4 md:gap-10 flex-col md:flex-row`}>
                {fetching && <Preloader />}
                {voicesPopup && (
                    <Popup OnHide={() => setVoicesPopup(false)} className={`!min-w-[1000px] !max-w-[1000px]`}>
                        <div className="flex flex-col p-10 min-h-[600px] max-h-[600px] overflow-y-auto gap-6">
                            <div className="text-lg font-semibold">Select Voice</div>
                            <ListVoices
                                selectedId={genData?.voiceid}
                                genData={genData}
                                for={"select"}
                                OnUse={(voice) => {
                                    setSelectedVoice(voice);
                                    console.log("voice ::", voice);
                                    if (voice.id) {
                                        genData.voiceid = voice.id;
                                    }
                                    setGenData({ ...genData });
                                    setVoicesPopup(false);
                                }}
                                cols={3}
                            />
                        </div>
                    </Popup>
                )}

                <div className="max-w-[1200px] min-w-[1200px] mx-auto flex flex-col gap-10 py-10">
                    <div className="flex gap-5 ">
                        <div className="cursor-pointer flex gap-2 items-center " onClick={() => navigate(-1)}>
                            <Iicon icon={"arrow-left"} />
                            {/* <div>Back</div> */}
                        </div>
                        <div className="text-lg font-semibold">
                            <input
                                type="text"
                                value={genData.name}
                                onChange={(e) => setGenData({ ...genData, name: e.target.value })}
                                className="bg-transparent border-b  border-dotted border-gray-300 focus:border-indigo-500 outline-none !pl-0"
                            />
                        </div>
                    </div>
                    <div className="flex-1 flex flex-row gap-10 pb-6">
                        <div className="flex-1 flex flex-col w-full  mx-auto slideFromRight">
                            <div className="flex flex-col flex-1 w-full h-full relative">
                                <AddText
                                    {...props}
                                    genData={genData}
                                    OnSet={(data) => {
                                        setGenData({ ...data });
                                    }}
                                />
                            </div>

                            <Dbox className={`relative  overflow-hidden p-4 mb-6 rounded-2xl`}>
                                {selectedVoice ? (
                                    <div className="flex flex-col gap-2">
                                        <CardAudio
                                            for={`thumbnail`}
                                            voice={selectedVoice}
                                            makeAudio={genData.text}
                                            actions={
                                                <button
                                                    className="button button-small px-4 button-bordered "
                                                    onClick={() => {
                                                        setVoicesPopup(true);
                                                    }}
                                                >
                                                    change
                                                </button>
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={`transition p-2 bg-lightblue100/50 h-[100px] border border-dashed border-blue rounded-2xl p-5 text-center flex flex-col gap-2 items-center justify-center cursor-pointer`}
                                        onClick={() => {
                                            setVoicesPopup(true);
                                        }}
                                    >
                                        <MdOutlineRecordVoiceOver className="text-xl text-indigo-500/80" />
                                        <div className="text-indigo-500/80">Select a Voice</div>
                                    </div>
                                )}
                            </Dbox>

                            {genData.result && (
                                <button
                                    className="button"
                                    disabled={!canSubmit()}
                                    onClick={async () => {
                                        await handleGenerate();
                                    }}
                                >
                                    Duplicate
                                </button>
                            )}

                            {genData.url && (
                                <button
                                    className={`button ${!canSubmit() ? "opacity-50 cursor-not-allowed" : ""}`}
                                    disabled={!canSubmit()}
                                    onClick={async () => {
                                        handleGenerate();
                                        //await generateLatentSync();
                                    }}
                                >
                                    Generate Video
                                </button>
                            )}
                        </div>
                        <div className="flex-1 flex flex-col w-full  mx-auto slideFromRight relative overflow-hidden rounded-2xl">
                            {genData.result && genData.result.includes("https://") && <VideoPlayer src={genData.result} fluid={true} fit={`contain`} />}
                            {genData.url && (
                                <div className="w-full h-full">
                                    {latentLoading && <Preloader />}
                                    {!latentResultVid && genData.url && genData.url.includes("https://") && (
                                        <VideoPlayer src={genData.url} fluid={true} fit={`contain`} />
                                    )}
                                    {latentResultVid && <VideoPlayer src={latentResultVid} fluid={true} fit={`contain`} />}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </AdminLayout>
        </>
    );
};

export default GenerateVideo;
