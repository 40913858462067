import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardVideo from "src/modules/admin/inputed-videos/card-video";
import OnBottom from "src/common/components/on-bottom/on-bottom";
import Preloader from "src/common/components/preloader/preloader";
import { upload } from "src/common/services/utils";
import { getInputVideos, getInputVideo } from "src/common/services/input";
import VideoPlayer from "src/common/components/video-player/video-player";
import { getStudioVideos } from "src/common/services/generate";
import { getAvatarVideos } from "src/common/services/avatars";

const ListAvatars = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [videos, setVideos] = useState([]);
    const [selVideo, setSelVideo] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [cardActions, setCardActions] = useState(null);
    const [fetching, setFetching] = useState(null);
    const [onUse, setOnUse] = useState(null);

    useEffect(() => {
        console.log("props.selectedDirectory ::", props.selectedDirectory);
        handleVideos({ opr: "list" });
    }, []);

    useEffect(() => {
        if (props.action && props.action.type) {
            switch (props.action.type) {
                case "upload":
                    handleVideos({ opr: "upload", file: props.action.file });

                    break;

                default:
                    break;
            }
        }
    }, [props.action]);

    useEffect(() => {
        if (props.OnUse && onUse) {
            props.OnUse({ ...onUse });
        }
    }, [onUse]);

    const handleVideos = async ({ opr, file, pageNum, id }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                try {
                    const parsedDirectory = props.selectedDirectory.replace("_video/", "");
                    const list_res = await getAvatarVideos(parsedDirectory);
                    console.log("list_res ::", list_res);
                    setVideos(list_res);
                } catch (error) {
                    //console.log("error ::", error);
                }

                break;

            case "get":
                try {
                    const get_res = await getInputVideo({ id });
                    //console.log(`get_res ::`, get_res);
                    setSelVideo({ ...get_res[0] });
                } catch (error) {
                    //console.log("error ::", error);
                }

                break;

            case "upload":
                if (file) {
                    try {
                        const upload_res = await upload({ file, directory: `_video/${props.selectedDirectory}`});
                        //console.log("upload_res ::", upload_res);
                        await handleVideos({ opr: "list", pageNum: 1 });
                        if (props.OnUploaded) {
                            props.OnUploaded(upload_res);
                        }
                    } catch (error) {
                        //console.log(error);
                        if (props.OnError) {
                            props.OnError(error);
                        }
                    }
                }
                break;
            default:
                break;
        }

        setFetching(false);
    };

    return (
        <div className="min-h-full h-full w-full relative slideFromTop flex-1">
            {fetching && pageNum === 1 && <Preloader />}

            {/* {selVideo && (
                <div className="flex">
                    <div className="aspect-square w-32 h-52 border-lightblue100/50  rounded-3xl relative overflow-hidden">
                        <VideoPlayer
                            image={`${selVideo.url}/frame1.jpg`}
                            src={selVideo.url}
                            rounded={false}
                            fluid={true}
                            fit={"cover"}
                            hoverPlay={true}
                            actions={false}
                        />
                    </div>
                </div>
            )} */}

            <OnBottom
                className={`flex-1`}
                OnBottom={async (val) => {
                    if (pageNum > 1 && props.paging !== "manual") {
                        await handleVideos({ opr: "list", pageNum });
                    }
                }}
            >
                {videos.length > 0 && (
                    <div className={`grid grid-cols-1 gap-6 justify-between ${props.cols ? `grid-cols-${props.cols}` : `grid-cols-6`}`}>
                        {videos.map((video, v) => (
                            <CardVideo
                                {...props}
                                //thumbsize={(props.for==='select')? '300': '400'}
                                key={v}
                                video={video}
                                OnUse={(video) => {
                                    setOnUse(video);
                                }}
                                OnDelete={async () => {
                                    await handleVideos({ opr: "list", pageNum: 1 });
                                }}
                            />
                        ))}
                    </div>
                )}

                {!fetching && props.paging === "manual" && videos?.length > 10 && (
                    <div
                        className=" transition p-3 cursor-pointer mt-6 flex items-center justify-center border border-lightblue100 text-black/50 rounded-xl hover:text-black/100 hover:bg-lightblue100/30"
                        onClick={async () => {
                            await handleVideos({ opr: "list", pageNum });
                        }}
                    >
                        Load more
                    </div>
                )}
            </OnBottom>
            {videos.length <= 0 && fetching !== null && props.for !== "studio" && (
                <div className="flex h-full flex-1 items-center justify-center">
                    <div className="flex flex-col gap-4 items-center">
                        {/* <div className="border-2 border-indigo-500/10 rounded-full w-28 h-28 flex items-center justify-center mb-6">
                            <CiVideoOff className="text-6xl text-indigo-500/20 " />
                        </div> */}
                        You have not added any videos yet.
                        <button className="button button-small px-4">
                            Upload
                            <input
                                title=""
                                type="file"
                                accept={`video/*`}
                                className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                                onChange={async (e) => {
                                    //console.log(e.target.files[0]);
                                    await handleVideos({ opr: "upload", file: e.target.files[0] });
                                }}
                            />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListAvatars;
