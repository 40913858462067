import { useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { PiDotsSixBold } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";

import { FaCheck } from "react-icons/fa";

import StageContainer from "../artboard/stage";
import { handleDownload } from "src/common/utils/utils";
import { MdOutlineFileDownload } from "react-icons/md";
import Preloader from "src/common/components/preloader/preloader";

import { handleScene } from "../handlers/scene-handler";

const getSceneDimensions = (project) => ({
    width: (120 * project.width) / project.height,
    height: 120,
});

const Scene = ({
    project,
    scene,
    setProject,
    scenes,
    setScenes,
    selectedScene,
    setSelectedScene,
    selectedDetail,
    setSelectedDetail,
    editMode,
    stageSize,
    previewOnly,
}) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: scene.id });

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        zIndex: isDragging ? 1 : 0,
        boxShadow: isDragging ? "0 6px 12px rgba(111, 118, 187, 0.12)" : "none",
        backgroundColor: isDragging ? "#ffffff" : undefined,
        // cursor: isDragging ? "grabbing" : "pointer",
    };

    // Calculate scale factor based on the container dimensions
    const containerDims = getSceneDimensions(project);
    const scale = Math.min(containerDims.width / stageSize.width, containerDims.height / stageSize.height);

    useEffect(() => {
     //   console.log(`!!! scene :: ${scene.name}`, scene);
        // Only fetch steps if they don't already exist for this scene
        // if (!scene.steps) {
        //     console.log("getttng steps   :: ");
        //     handleScene({
        //         opr: "getSteps",
        //         scene: scene,

        //         project,
        //         stageSize,
        //         setSelectedScene,

        //         setScenes,

        //         callback: (res_steps) => { 
        //             if (selectedScene?.id === scene?.id) {
        //                 setSelectedScene({ ...scene, steps: res_steps });
        //             }
        //         },
        //     });
        // }
    }, [scene]);
    return (
        <>
            {scene && (
                <div
                    ref={setNodeRef}
                    style={style}
                    className={`scene-item items-center justify-center flex flex-col gap-2 min-w-32  rounded-2xl ${isDragging ? "opacity-90" : ""}`}
                >
                    <div
                        className={`transition-all scene-video rounded-2xl overflow-hidden relative border-2 group flex items-center justify-center ${
                            !previewOnly && scene.id === selectedScene?.id ? "border-blue" : "border-lightblue100"
                        }
                        
                        `}
                    >
                        <div
                            className={`w-full h-full bg-white ${!previewOnly ? "cursor-pointer" : ""} z-40 relative overflow-hidden ${
                                (!previewOnly && scene.id === selectedScene?.id) || scene.active ? "" : "opacity-50"
                            }`}
                            onClick={() => handleScene({ opr: "select", scene, selectedScene, setSelectedScene, setSelectedDetail })}
                            style={getSceneDimensions(project)}
                        >
                            {scene.generating && <Preloader logo={false} text={project.proportion === "9:16" ? " " : "Generating"} />}

                            <StageContainer
                                //scene={scene}
                                previewOnly={true}
                                project={project}
                                stageSize={stageSize}
                                setSelectedDetail={setSelectedDetail}
                                selectedDetail={selectedDetail}
                                selectedScene={scene}
                                setSelectedScene={setSelectedScene}
                                scaleX={scale}
                                scaleY={scale}
                            />
                            {/* {scene.active && <div className="absolute top-0 left-0 w-full h-full bg-blue/30"></div>} */}
                        </div>

                        {handleScene && (
                            <div className={`scene-video-actions absolute top-0 right-0 p-2 h-full z-50 flex flex-col ${editMode ? "visible" : "hidden"}`}>
                                <div className="flex flex-1 flex-col gap-2 bg-white rounded-lg p-2 items-center justify-between">
                                    <div
                                        className="flex flex-row gap-2 cursor-pointer"
                                        onClick={() =>
                                            handleScene({
                                                opr: "delete",
                                                scene,
                                                project,
                                                setScenes,
                                                scenes,
                                                selectedDetail,
                                                setSelectedDetail,
                                                selectedScene,
                                                setSelectedScene,
                                            })
                                        }
                                    >
                                        <FaRegTrashAlt className="text-lg w-4 h-4 text-black" />
                                    </div>
                                    <div className="flex flex-row gap-2 cursor-grab" {...attributes} {...listeners}>
                                        <PiDotsSixBold className="text-xl w-5 h-5 text-black" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={`scene-name  flex flex-row items-center justify-between gap-2 ${project.proportion === "9:16" ? "" : "w-full"}`}>
                        <div className="text-sm">
                            {!scene?.name
                                ? "Untitled"
                                : scene.name.length > (project.proportion === "9:16" ? 8 : 15)
                                ? scene.name.substring(0, project.proportion === "9:16" ? 8 : 15) + "..."
                                : scene.name}
                        </div>
                        <div className="flex flex-row gap-2 items-center justify-center">
                            {/* {scene?.result?.src && scene?.result?.sceneid === scene?.id && (
                        <MdOutlineFileDownload
                            className="text-lg w-5 h-5 text-black cursor-pointer hover:text-blue"
                            onClick={() => {
                                handleDownload(scene?.result?.url, `${scene?.name}.mp4`);
                            }}
                        />
                    )} */}

                            {handleScene && scene?.result?.indexOf("https") !== -1 && (
                                <div className="scene-video-check flex flex-col z-20" onClick={() => handleScene({ opr: "active", scene, setScenes, scenes })}>
                                    <div
                                        className={`flex items-center justify-center w-5 h-5 border border-blue rounded-md ${
                                            scene?.active ? "bg-blue" : "bg-white "
                                        }`}
                                    >
                                        {scene?.active && <FaCheck className=" w-3 h-3 text-white" />}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Scene;
