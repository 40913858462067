import axios from "axios";
import { getStoredUser } from "src/common/components/auth/auth";
import endpoint from "./endpoints";
import { handleRes, handleErr } from "./response";

export const generateAvatar = async (input) => {
    const userid = getStoredUser()?.id;
    input.userid = userid;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                api: "avatar_generatenew",
            },
            Values: input,
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getGeneratedAvatars = async () => {
    const userid = getStoredUser()?.id;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                api: "avatar_generated",
            },
            Values: {
                userid,
            },
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getAvatars = async () => {
    const userid = getStoredUser()?.id;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                api: "avatar_dir",
            },
            Values: {
                userid,
            },
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getAvatarVideos = async (directory) => {
    const userid = getStoredUser()?.id;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                api: "avatar_dirdetails",
            },
            Values: {
                directory,
                userid,
            },
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getAvatarVideo = async ({ id }) => {
    const userid = getStoredUser()?.id;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                api: "avatar_getmedia",
            },
            Values: {
                id,
                userid,
            },
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const deleteAvatarVideo = async ({ id }) => {
    const userid = getStoredUser()?.id;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                api: "avatar_deletemedia",
            },
            Values: {
                id,
                userid,
            },
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

//VOICES//

export const getAvatarVoices = async () => {
    const userid = getStoredUser()?.id;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                api: "avatar_voices",
            },
            Values: {
                userid,
            },
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};
