import axios from "axios";
import { getStoredUser } from "src/common/components/auth/auth";
import endpoint from "./endpoints";
import { handleRes, handleErr } from "./response";

export const getLLMModels = async () => {
    const userid = getStoredUser()?.id;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                userid,
                auth: "xxxx",
                sys: "openrouter",
                act: "models"
            }
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getLLMPrompt = async ({prompt,model}) => {
    const userid = getStoredUser()?.id;
    const config = {
        method: "post",
        url: `${endpoint.ngrok}`,
        data: {
            Params: {
                userid,
                auth: "xxxx",
                sys: "openrouter",
                act: "prompt"
            },
            Values: {
                prompt,
            },
        },
        headers: {
            "Content-Type": "application/json",
        },
    };

    if(model){
        config.data.Values.model = model;
    }

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};
