import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleErr, handleRes } from "src/common/services/response";

////STEPS

export const listSteps = async ({ sceneid, pageNum }) => {
    const token = getStoredUser()?.token;
    pageNum = pageNum || 1;
    const config = {
        method: "get",
        url: `${endpoint.rest}step/all/${sceneid}?pagesize=30&pagenumber=${pageNum}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const addStep = async (input) => {
    const token = getStoredUser()?.token;
    const stringifiedStep = Object.fromEntries(
        Object.entries(input).map(([key, value]) => [key, String(value)])
    );
    const config = {
        method: "post",
        url: `${endpoint.rest}step/addstep`,
        data: stringifiedStep,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const updateStep = async (id, step) => {
    const token = getStoredUser()?.token;
    
    // Convert all step fields to strings
    const stringifiedStep = Object.fromEntries(
        Object.entries(step).map(([key, value]) => [key, String(value)])
    );

    const config = {
        method: "post",
        url: `${endpoint.rest}step/${id}`,
        data: stringifiedStep,  // Use the stringified version
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const deleteStep = async ({ id }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "delete",
        url: `${endpoint.rest}step/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getStep = async ({ id }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "get",
        url: `${endpoint.rest}step/${id}`,

        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};


export const reorderStep = async (steps) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}reorder/step`,
        data: {
            value: JSON.stringify(steps),
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };
    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};