import { useEffect, useState, useRef } from "react";
import iconPlay from "src/common/assets/images/icon-play.svg";
import iconPause from "src/common/assets/images/icon-pause.svg";
import logoIcon from "src/common/assets/images/logo-white.svg";
import { FaVolumeUp, FaVolumeMute } from "react-icons/fa";

import "./video-player.css";
import Iicon from "../iicon/iicon";

const VideoPlayer = (props) => {
    let videoRef = useRef();
    const [vidState, setVidState] = useState("paused");
    const [hasLoaded, setHasLoaded] = useState(false);
    const [imageError, setImageError] = useState(false);

    const [play, setPlay] = useState(false);
    const [isMuted, setIsMuted] = useState(props.hoverPlay ? true : props.muted);

    useEffect(() => {
        if (videoRef.current) {
            if (props.preload) {
                videoRef.current.preload = props.preload;
            }
            if (props.image) {
                videoRef.current.autoplay = false;
            }

            // if (props.hoverPlay) {
            //     videoRef.current.addEventListener("mouseover", (event) => {
            //         setPlay(true);
            //     });
            //     videoRef.current.addEventListener("mouseleave", (event) => {
            //         setPlay(false);
            //     });
            // }

            videoRef.current.addEventListener("canplaythrough", (event) => {
                setHasLoaded(true);
            });
            videoRef.current.addEventListener("playing", (event) => {
                setVidState("playing");
            });

            videoRef.current.addEventListener("ended", (event) => {
                setPlay(false);
                setVidState("paused");
            });
            videoRef.current.addEventListener("pause", (event) => {
                setVidState("paused");
            });
        }
    }, [videoRef.current]);

    useEffect(() => {
        setImageError(null);
    }, [props.image]);

    useEffect(() => {
        if (!videoRef.current) return;

        if (play) {
            setVidState("loading");

            videoRef.current
                .play()
                .then(() => {})
                .catch((error) => {
                    //console.log("error playing video :", error);
                });
        } else {
            videoRef.current.pause();
        }
    }, [play]);

    const canPlay = () => {
        if (props.src) {
            switch (true) {
                case play === false && !hasLoaded:
                    return "toload";

                case play === true && !hasLoaded:
                    return "loading";

                case play === false && hasLoaded:
                    return "canplay";

                case play === true && hasLoaded:
                    return "playing";

                default:
                    break;
            }
        } else {
            return "nosource";
        }
    };
    return (
        <div
            className={`video-player-wrapper ${props.className ? props.className : ""}  ${props.fluid ? "video-player-wrapper-fluid flex-1" : ""}`}
            onClick={(e) => {
                if (props.onClick) {
                    props.onClick(e);
                }
            }}
            onMouseOver={() => {
                if (props.hoverPlay) {
                    setPlay(true);
                }
            }}
            onMouseLeave={() => {
                if (props.hoverPlay) {
                    setPlay(false);
                }
            }}
        >
            <>
                <div className={`aspect-video w-full h-full bg-black-800 absolute top-0 left-0 overflow-hidden ${props.rounded === false ? "" : ""}  flex`}>
                    {props.src?.indexOf("blob") === -1 && (
                        <>
                            {props.image && props.image !== "" && !imageError && (
                                <img
                                    src={props.image}
                                    className={`w-full h-full ${props.fit ? `object-${props.fit}` : `object-contain`} ${
                                        canPlay() === "loading" ? "blinking-half" : " "
                                    }`}
                                    onError={(e) => {
                                        //console.log('image error::', e)
                                        setImageError(true);
                                    }}
                                />
                            )}

                            {props.image && imageError && (
                                <div className="flex items-center justify-center flex-1 flex-col gap-3">
                                    <img src={logoIcon} className={`w-1/3 ${canPlay() === "loading" ? "blinking" : " "}`} />
                                    <div className="text-xs text-white p-4 text-center opacity-40">Cannot load image placeholder</div>
                                </div>
                            )}

                            {!props.image && !hasLoaded && (
                                <div className="flex items-center justify-center flex-1">
                                    <img src={logoIcon} className={`w-1/3 ${canPlay() === "loading" ? "blinking" : " "}`} />
                                </div>
                            )}
                        </>
                    )}
                </div>

                {props.vidthumb && !play && (
                    <video
                        className={`${props.fit ? `object-${props.fit} w-full h-full` : ``} ${props.audioOnly === true ? "opacity-0" : ""}`}
                        src={props.src + "/thumbnail.mp4"}
                        autoPlay
                        playsInline
                        muted
                        loop
                    />
                )}

                {(play || !props.image || props.src?.indexOf("blob") !== -1) && (
                    <video
                        className={`${props.fit ? `object-${props.fit} w-full h-full` : ``} ${props.audioOnly === true ? "opacity-0" : ""}`}
                        src={props.src}
                        autoPlay={props.autoplay === true ? true : false}
                        playsInline
                        ref={videoRef}
                        muted={isMuted}
                        loop={props.loop}
                        style={{ opacity: hasLoaded ? 1 : 0 }}
                    />
                )}
                {props.hoverPlay && (
                    <div
                        className="absolute bottom-4 left-4 z-50 text-white"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsMuted(!isMuted);
                            videoRef.current.muted = isMuted;
                        }}
                    >
                        {isMuted ?  <FaVolumeMute className="w-5 h-5" /> : <FaVolumeUp className="w-4 h-4" />}
                    </div>
                )}

                {props.src && props.src !== "" && props.actions !== false && (
                    <div className="video-wrapper-actions z-40">
                        {vidState === "playing" && (
                            <div
                                className="button-action"
                                onClick={() => {
                                    setPlay(false);
                                }}
                            >
                                <img src={iconPause} />
                            </div>
                        )}

                        {vidState === "loading" && (
                            <div
                                className="button-action items-center flex justify-center spinning"
                                onClick={() => {
                                    setPlay(false);
                                }}
                            >
                                <Iicon icon={"reload-white"} />
                            </div>
                        )}

                        {vidState === "paused" && (
                            <div
                                className="button-action"
                                onClick={() => {
                                    setPlay(true);
                                    //videoRef.current.play();
                                }}
                            >
                                <img src={iconPlay} />
                            </div>
                        )}
                    </div>
                )}
            </>
        </div>
    );
};

export default VideoPlayer;
