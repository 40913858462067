import { useEffect, useState, useRef } from "react";
import { getLLMModels, getLLMPrompt } from "src/common/services/openrouter";
import { TbX } from "react-icons/tb";
import { LiaBrainSolid } from "react-icons/lia";
import Preloader from "src/common/components/preloader/preloader";

const UseLLM = (props) => {
    const { genData } = props;
    const textareaRef = useRef(null);
    const MAX_CHARS = 1000;
    const [models, setModels] = useState([]);
    const [prompt, setPrompt] = useState(null);
    const [model, setModel] = useState(null);
    const [fetching, setFetching] = useState(false);

    const [showLLM, setShowLLM] = useState(false);

    useEffect(() => {
        // Auto focus the textarea and move cursor to end
        if (textareaRef.current) {
            textareaRef.current.focus();
            const length = textareaRef.current.value.length;
            textareaRef.current.setSelectionRange(length, length);
        }
        handleLLM({ opr: "list" });
    }, []);

    const handleLLM = async ({ opr, prompt }) => {
       
        
        switch (opr) {
            case "list":
                try {
                    const list_res = await getLLMModels();
                    console.log("list_res :: ", list_res);
                    setModels(list_res);
                } catch (err) {
                    console.log("err :: ", err);
                }
                break;

            case "prompt":
                if (!prompt || fetching) return;
                setFetching(true);
                try {
                    const prompt_res = await getLLMPrompt({ prompt, model });
                    console.log("prompt_res :: ", prompt_res);
                    if (props.OnSet && prompt_res[0]) {
                        props.OnSet({ ...genData, text: prompt_res[0].response });
                    }
                } catch (err) {
                    console.log("err :: ", err);
                }
                setFetching(false);
                break;
        }
       
    };

    return (
        <div className="flex-1 flex flex-col gap-4 ">
            {fetching && <Preloader text="Generating your text..." />}

            {!showLLM && (
                <div className="flex flex-col gap-2 items-start">
                    <button className="button button-white button-small gap-2 flex items-center justify-center pl-2" onClick={() => setShowLLM(true)}>
                        <LiaBrainSolid className="w-6 h-6" />
                        Generate your text
                    </button>
                </div>
            )}
            {showLLM && (
                <div className="flex-1 flex flex-col gap-8">
                    {/* <div>Add some instructions here...</div> */}
                    <div className="border flex flex-col  flex-1 border-lightblue100 rounded-2xl relative overflow-hidden bg-lightblue50 bg-white">
                        <div
                            className="absolute top-1 right-1 bg-white rounded-full w-8 h-8 flex items-center justify-center cursor-pointer"
                            onClick={() => setShowLLM(false)}
                        >
                            <TbX className="w-4 h-4" />
                        </div>
                        <textarea
                            ref={textareaRef}
                            value={prompt}
                            className="flex flex-1 w-full p-5"
                            rows={2}
                            maxLength={MAX_CHARS}
                            placeholder={`Describe what you want your avatar to say ...`}
                            onChange={(e) => {
                                setPrompt(e.target.value);
                            }}
                        />

                        <div className="p-3 flex justify-between gap-7 items-center border-t border-lightblue100">
                            <select className="w-full" value={model} onChange={(e) => setModel(e.target.value)}>
                                <option value="">Use Default</option>
                                {models.map((model) => (
                                    <option key={model.id} value={model.id}>
                                        {model.name}
                                    </option>
                                ))}
                            </select>

                            <button className="button button-small" onClick={() => handleLLM({ opr: "prompt", prompt })}>
                                Generate
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UseLLM;
