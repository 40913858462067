export const spec = {
    openapi: "3.0.0",
    info: {
        title: "DigitalU API",
        version: "BETA",
    },
    servers: [
        {
            url: "https://svc.staging.squadz.live",
            description: "Production",
        },
        // {
        //     url: "http://localhost",
        //     description: "Local development"
        // }
    ],
    tags: [
        { name: "Auth", description: "Authentication operations" },
        { name: "Generate", description: "Generation operations" },
        { name: "Image", description: "Image operations" },
        { name: "Video", description: "Video operations" },
        { name: "Audio", description: "Audio operations" },
        { name: "Voice", description: "Voice operations" },
        { name: "Speech", description: "Speech operations" },
    ],
    components: {
        securitySchemes: {
            ApiKeyAuth: {
                type: "apiKey",
                in: "header",
                name: "Authorization",
            },
        },
    },
    security: [{ ApiKeyAuth: [] }],
    paths: {
        "/login": {
            post: {
                tags: ["Auth"],
                summary: "Login",
                security: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    Params: {
                                        type: "object",
                                        properties: {
                                            sys: {
                                                type: "string",
                                                default: "auth",
                                            },
                                            act: {
                                                type: "string",
                                                default: "login",
                                            },
                                        },
                                        required: ["sys", "act"],
                                    },
                                    Where: {
                                        type: "object",
                                        properties: {
                                            email: { type: "string" },
                                        },
                                        required: ["email"],
                                    },
                                },
                                required: ["Params", "Where"],
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        result: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    userid: { type: "string" },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/getauth": {
            post: {
                tags: ["Auth"],
                summary: "Get auth",
                security: [],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    email: { type: "string" },
                                    token: { type: "string" },
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "array",
                                    items: {
                                        type: "object",
                                        properties: {
                                            authorization: { type: "string" },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/generate/all": {
            get: {
                tags: ["Generate"],
                summary: "Get all generations",
                security: [{ "Authorization": [] }],
                parameters: [
                    {
                        name: "orderby",
                        in: "query",
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        result: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    createdate: { type: "string" },
                                                    id: { type: "string" },
                                                    name: { type: "string" },
                                                    result: { type: "string" },
                                                    state: { type: "string" },
                                                    text: { type: "string" },
                                                    usevoice: { type: "string" },
                                                    videoid: { type: "string" },
                                                    voiceid: { type: "string" },
                                                    imageid: { type: "string" },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    401: {
                        description: "Unauthorized",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        message: { type: "string" }
                                    }
                                }
                            }
                        }
                    }
                },
            },
        },
        "/api/generate/{id}": {
            get: {
                tags: ["Generate"],
                summary: "Get generation by ID",
                security: [{ "Authorization": [] }],
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        result: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    createdate: { type: "string" },
                                                    id: { type: "string" },
                                                    name: { type: "string" },
                                                    result: { type: "string" },
                                                    state: { type: "string" },
                                                    text: { type: "string" },
                                                    usevoice: { type: "string" },
                                                    videoid: { type: "string" },
                                                    voiceid: { type: "string" },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    401: {
                        description: "Unauthorized",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        message: { type: "string" }
                                    }
                                }
                            }
                        }
                    },
                    404: {
                        description: "Not Found",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        message: { type: "string" }
                                    }
                                }
                            }
                        }
                    }
                },
            },
            delete: {
                tags: ["Generate"],
                summary: "Delete generation by ID",
                security: [{ "Authorization": [] }],
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        result: {
                                            type: "null",
                                        },
                                    },
                                },
                            },
                        },
                    },
                    401: {
                        description: "Unauthorized",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        message: { type: "string" }
                                    }
                                }
                            }
                        }
                    },
                    404: {
                        description: "Not Found",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        message: { type: "string" }
                                    }
                                }
                            }
                        }
                    }
                },
            },
        },
        "/api/generate/public/{id}": {
            get: {
                tags: ["Generate"],
                summary: "Get public generation by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        result: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    message: { type: "string" },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    404: {
                        description: "Not Found",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        message: { type: "string" }
                                    }
                                }
                            }
                        }
                    }
                },
            },
        },
        "/api/generate/nobg/{id}": {
            get: {
                tags: ["Generate"],
                summary: "Get generation without background",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        result: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    message: { type: "string" },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/generate/upscaled/{id}": {
            get: {
                tags: ["Generate"],
                summary: "Get upscaled generation",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        result: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    message: { type: "string" },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/generate/script": {
            get: {
                tags: ["Generate"],
                summary: "Get generation script",
                responses: {
                    200: {
                        description: "Success",
                        content: {
                            "application/json": {
                                schema: {
                                    type: "object",
                                    properties: {
                                        result: {
                                            type: "array",
                                            items: {
                                                type: "object",
                                                properties: {
                                                    script: { type: "string" },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        "/api/generate/image": {
            post: {
                tags: ["Generate"],
                summary: "Generate image",
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                required: ["usevoice"],
                                oneOf: [
                                    {
                                        properties: {
                                            usevoice: { 
                                                type: "string",
                                                enum: ["fromId"]
                                            },
                                            imageurl: { type: "string" },
                                            name: { type: "string" },
                                            text: { type: "string" },
                                            voiceid: { type: "string" }
                                        },
                                        required: ["imageurl"]
                                    },
                                    {
                                        properties: {
                                            usevoice: { 
                                                type: "string",
                                                enum: ["direct"]
                                            },
                                            imageurl: { type: "string" },
                                            name: { type: "string" },
                                            text: { type: "string" },
                                            audiourl: { type: "string" }
                                        },
                                        required: ["audiourl"]
                                    }
                                ]
                            }
                        }
                    }
                },
                responses: {
                    200: { description: "Success" }
                }
            }
        },
        "/api/generate/video": {
            post: {
                tags: ["Generate"],
                summary: "Generate video",
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    name: { type: "string" },
                                    usevoice: { type: "string" },
                                    text: { type: "string" },
                                    videourl: { type: "string" },
                                    voiceid: { type: "string" },
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/image/all": {
            get: {
                tags: ["Image"],
                summary: "Get all images",
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/image/{id}": {
            get: {
                tags: ["Image"],
                summary: "Get image by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
            delete: {
                tags: ["Image"],
                summary: "Delete image by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
            patch: {
                tags: ["Image"],
                summary: "Update image by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    voiceName: { type: "string" },
                                    auth: { type: "string" },
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/video/all": {
            get: {
                tags: ["Video"],
                summary: "Get all videos",
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/video/{id}": {
            get: {
                tags: ["Video"],
                summary: "Get video by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
            delete: {
                tags: ["Video"],
                summary: "Delete video by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
            patch: {
                tags: ["Video"],
                summary: "Update video by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    url: { type: "string" },
                                    auth: { type: "string" },
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/video/driver/all": {
            get: {
                tags: ["Video"],
                summary: "Get all driver videos",
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/video/driver/{id}": {
            get: {
                tags: ["Video"],
                summary: "Get driver video by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
            delete: {
                tags: ["Video"],
                summary: "Delete driver video by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/audio/all": {
            get: {
                tags: ["Audio"],
                summary: "Get all audio",
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/audio/{id}": {
            get: {
                tags: ["Audio"],
                summary: "Get audio by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
            delete: {
                tags: ["Audio"],
                summary: "Delete audio by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/voice/all": {
            get: {
                tags: ["Voice"],
                summary: "Get all voices",
                parameters: [
                    {
                        name: "age",
                        in: "query",
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/voice/default": {
            get: {
                tags: ["Voice"],
                summary: "Get default voice",
                parameters: [
                    {
                        name: "description",
                        in: "query",
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/voice/eleven": {
            get: {
                tags: ["Voice"],
                summary: "Get eleven voice",
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/voice/{id}": {
            get: {
                tags: ["Voice"],
                summary: "Get voice by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
            delete: {
                tags: ["Voice"],
                summary: "Delete voice by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                responses: {
                    200: { description: "Success" },
                },
            },
            patch: {
                tags: ["Voice"],
                summary: "Update voice by ID",
                parameters: [
                    {
                        name: "id",
                        in: "path",
                        required: true,
                        schema: { type: "string" },
                    },
                ],
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    voiceName: { type: "string" },
                                    auth: { type: "string" },
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/voice": {
            post: {
                tags: ["Voice"],
                summary: "Create new voice",
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    name: { type: "string" },
                                    description: { type: "string" },
                                    accent: { type: "string" },
                                    age: { type: "string" },
                                    usecase: { type: "string" },
                                    gender: { type: "string" },
                                    fileurl: { type: "string" },
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: { description: "Success" },
                },
            },
        },
        "/api/speech": {
            post: {
                tags: ["Speech"],
                summary: "Generate speech",
                requestBody: {
                    required: true,
                    content: {
                        "application/json": {
                            schema: {
                                type: "object",
                                properties: {
                                    voiceid: { type: "string" },
                                    text: { type: "string" },
                                },
                            },
                        },
                    },
                },
                responses: {
                    200: { description: "Success" },
                },
            },
        },
    },
};
