import { Rect, Group, Transformer, Text } from "react-konva";
import { useEffect, useRef } from "react";

import { getRealVal } from "../../studio-funcs";
import { parseTextEffects } from "./utils";
import { positions } from "../../common";
import ImageLayer from "./image";


export const SubtitleLayer = ({ project, detail, previewOnly, selectedDetail, setSelectedDetail, stageSize, setSelectedScene,selectedScene }) => {
    const shapeRef = useRef();
    const transformerRef = useRef();
    // Calculate dimensions based on font size
    const subtitleDimension = {
        width: detail?.params?.font_size * 8,
        height: detail?.params?.font_size * 2,
    };
    const thePositions = positions(stageSize, subtitleDimension);

    const positionCoordinates = {
        x: thePositions[2].x,
        y: thePositions[2].y,
    };

    const parseColorValue = (value) => {
        if (typeof value === "string" && value.startsWith("&H")) {
            return "#" + value.substring(2);
        }
        return value;
    };

    Object.entries(detail.params || {}).forEach(([key, value]) => {
        if (typeof value === "string" && value.startsWith("&H")) {
            detail.params[key] = parseColorValue(value);
        }
    });
    if (detail.params.position && detail.params.position !== "undefined") {
        const subtitleDimension = {
            width: detail.params?.font_size * 8,
            height: detail.params?.font_size * 2,
        };

        positionCoordinates.x = thePositions.find((pos) => pos.label === detail.params.position).x;
        positionCoordinates.y = thePositions.find((pos) => pos.label === detail.params.position).y;
    }

   

    useEffect(() => {
        if (selectedDetail?.id === detail.id && transformerRef.current) {
            transformerRef.current.nodes([shapeRef.current]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [selectedDetail, detail.id]);

    // const updateDetail = ({ node }) => {
    //     if (!node) return;

    //     const currentNode = node || shapeRef.current;
    //     let x = currentNode.x();
    //     let y = currentNode.y();

    //     // Add position handling logic here
    //     if (
    //         detail?.params?.position &&
    //         (detail?.params?.x === "null" || detail?.params?.x === undefined || detail?.params?.y === "null" || detail?.params?.y === undefined)
    //     ) {
    //         const foundPosition = thePositions.find((pos) => pos.label === detail.params.position);

    //         if (foundPosition) {
    //             x = foundPosition.x;
    //             y = foundPosition.y;
    //         }
    //     }

    //     if (selectedDetail && selectedDetail?.id === detail.id) {
    //         // Update selected detail
    //         if (setSelectedDetail) {
    //             setSelectedDetail((prev) => ({
    //                 ...prev,
    //                 params: {
    //                     ...prev.params,
    //                     x,
    //                     y,
    //                 },
    //             }));
    //         }
    //     } else {
    //         // Update scene for non-selected details
    //         if (setSelectedScene) {
    //             setSelectedScene((prev) => {
    //                 if (prev?.steps) {
    //                     return {
    //                         ...prev,
    //                         steps: prev.steps.map((step) => {
    //                             return step.id === detail.id ? { ...step, params: { ...step.params, x, y } } : step;
    //                         }),
    //                     };
    //                 }
    //                 return prev;
    //             });
    //         }
    //     }

    //     // Reset scale after updating dimensions
    //     if (currentNode) {
    //         currentNode.scaleX(1);
    //         currentNode.scaleY(1);
    //     }
    // };

    const computeFontStyle = () => {
        const isBold = detail.params.font_style_bold === -1;
        const isItalic = detail.params.font_style_italic === 1;

        if (isBold && isItalic) return "bold italic";
        if (isBold) return "bold";
        if (isItalic) return "italic";
        return "normal";
    };

    return (
        <Group>


            <ImageLayer
                detail={detail}
                previewOnly={false}
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                selectedScene={selectedScene}
                setSelectedScene={setSelectedScene}
                stageSize={stageSize}
            />


            <Group
                onMouseDown={() => !previewOnly && setSelectedDetail(detail)}
                draggable={false}
                ref={shapeRef}
                x={positionCoordinates.x}
                y={positionCoordinates.y}
                width={subtitleDimension.width}
                height={subtitleDimension.height}
                // onTransformEnd={() => {
                //     const node = shapeRef.current;
                //     updateDetail({ node });
                // }}
                // onDragEnd={() => {
                //     const node = shapeRef.current;
                //     updateDetail({ node });
                // }}
            >
                <Text
                    text="[ Subtitle ]"
                    align="center"
                    width={subtitleDimension.width}
                    height={subtitleDimension.height}
                    verticalAlign="middle"
                    fontFamily={detail?.params?.font_family}
                    fontStyle={computeFontStyle()}
                    fontWeight={detail?.params?.font_weight}
                    fill={detail?.params?.font_color}
                    fontSize={parseInt(detail?.params?.font_size)}
                    {...parseTextEffects(detail?.params)}
                    scaleX={1 / (shapeRef.current?.scaleX() || 1)}
                    scaleY={1 / (shapeRef.current?.scaleY() || 1)}
                />
            </Group>

            {selectedDetail?.id === detail.id && !previewOnly && (
                <Transformer
                    ref={transformerRef}
                    anchorStroke="#6676FF"
                    borderStroke="#6676FF"
                    borderStrokeWidth={2}
                    anchorStrokeWidth={2}
                    resizeEnabled={false}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // Limit resize
                        const minWidth = 20;
                        const minHeight = 20;
                        const maxWidth = stageSize.width;
                        const maxHeight = stageSize.height;

                        if (newBox.width < minWidth || newBox.height < minHeight || newBox.width > maxWidth || newBox.height > maxHeight) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Group>
    );
};
