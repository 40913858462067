import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, horizontalListSortingStrategy } from "@dnd-kit/sortable";

import { useState, useEffect } from "react";
import SceneClass from "./class-scene";
import Scene from "./scene";

import StepClass from "../step/class-step";
import { createScene, listScenes, updateScene, deleteScene } from "src/common/services/scene";
import { useParams } from "react-router-dom";
import { act } from "react";
import { addStep } from "src/common/services/step";
import { reorderScene } from "src/common/services/scene";
import { handleScene } from "../handlers/scene-handler";
import { getScene } from "src/common/services/scene";
import { detectMediaType } from "../utils";

const getSceneDimensions = (project) => ({
    width: (120 * project.width) / project.height,
    height: 120,
});

const Scenes = (props) => {
    const params = useParams();

    const { scenes, setScenes, project, setProject, setSelectedScene, selectedScene, selectedDetail, setSelectedDetail, stageSize, setShowSceneAdd } = props;

    const [merging, setMerging] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [activeSceneCount, setActiveSceneCount] = useState(0);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 0, // 500ms = 0.5 seconds
                tolerance: 5, // Allow slight movement during delay
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        if (scenes) {
            const count = scenes.filter((scene) => scene.active && scene?.result?.indexOf("https") > -1).length;
            setActiveSceneCount(count);
        }
    }, [scenes]);

    useEffect(() => {
        console.log("activeSceneCount :: ", activeSceneCount);
    }, [activeSceneCount]);

    useEffect(() => {
        if (params.projectid && stageSize && stageSize.width > 0 && stageSize.height > 0) {
            handleScene({ opr: "list", project, scenes, setScenes, stageSize });
        }
    }, [params.projectid, stageSize]);

    const handleDragEnd = async (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = scenes.findIndex((scene) => scene.id === active.id);
            const newIndex = scenes.findIndex((scene) => scene.id === over.id);

            const newScenes = arrayMove(scenes, oldIndex, newIndex);
            console.log("newScenes :: ", newScenes);
            setScenes(newScenes);

            // setProject((prev) => ({ ...prev, scenes: newScenes }));
            const input_reorder = [];
            newScenes.forEach((scene, index) => {
                input_reorder.push({
                    id: scene.id,
                    index: parseInt(index + 1),
                });
            });

            console.log("reorder input:: ", input_reorder);
            const reorder_res = await reorderScene(input_reorder);
            console.log("reorder res :: ", reorder_res);
        }
    };

    const handleProject = async ({ opr }) => {
        const activeScenes = scenes.filter((scene) => scene.active && scene?.result?.indexOf("https") > -1);
        if (activeScenes.length < 2) {
            return;
        }
        switch (opr) {
            case "merge":
                //CREATE THE SCENE
                try {
                    const mergeScene = SceneClass.create({
                        index: (scenes.length + 1).toString(),
                        name: `Merged Scenes ${scenes.length + 1}`,
                        projectid: project.id,
                    });

                    const input_scene = { ...mergeScene };
                    input_scene.active = "false";
                    delete input_scene.steps;

                    const mergeScene_res = await createScene(input_scene);
                    mergeScene.id = mergeScene_res[0].id;

                    //CREATE THE STEP
                    const mergeStep = StepClass.init({
                        name: "Merge Scenes",
                        description: "Merge multiple scenes into one",
                        type: "transition",
                        action: "merge-videos",
                        params: {
                            option: "fade",
                            duration: "1",
                        },
                    });
                    // create scene1 scene2 scene3
                    const activeScenesResults = activeScenes.map((scene) => scene.result);
                    const processedScenes = activeScenesResults.reduce((acc, scene, index) => {
                        acc[`scene${index + 1}`] = scene;
                        return acc;
                    }, {});
                    mergeStep.params = { ...mergeStep.params, ...processedScenes };

                    const merge_res_step = await addStep({
                        index: "1",
                        label: "Merge Scenes",
                        action: "merge-videos",
                        type: "transition",
                        parentid: mergeScene_res[0].id,
                        ...mergeStep.params,
                    });

                    //RECREATE THE STEP OBJECT to use for localupdate
                    mergeStep.id = merge_res_step[0].id;
                    mergeStep.params.scenes = activeScenesResults.join(",");
                    console.log("mergeStep.params.scenes :: ", mergeStep.params.scenes);
                    mergeScene.addStep(mergeStep);

                    setScenes((prev) => [...prev, mergeScene]);
                    setSelectedScene(mergeScene);
                    setSelectedDetail(null);
                } catch (error) {
                    console.log("error :: ", error);
                }

                break;

            case "overlay":
                //CREATE THE SCENE
                try {
                    const overlayScene = SceneClass.create({
                        index: (scenes.length + 1).toString(),
                        name: `Overlayed Scenes ${scenes.length + 1}`,
                        projectid: project.id,
                    });

                    const input_scene_overlay = { ...overlayScene };
                    input_scene_overlay.active = "false";
                    delete input_scene_overlay.steps;

                    const overlayScene_res = await createScene(input_scene_overlay);
                    overlayScene.id = overlayScene_res[0].id;

                    //CREATE THE STEP
                    for (let index = 0; index < activeScenes.length; index++) {
                        const scene = activeScenes[index];
                        const mediaType = detectMediaType({ url: scene.result });
                        const overlayStep = StepClass.init({
                            name: mediaType,
                            action: `overlay-${mediaType}`,
                            type: mediaType,
                            params: {
                                src: scene.result,
                                x: "0",
                                y: "0",
                                width: "null",
                                height: "null",
                            },
                        });

                        const overlay_res_step = await addStep({
                            index: (index + 1).toString(),
                            label: "Overlay Scenes",
                            action: `overlay-${mediaType}`,
                            type: mediaType,
                            parentid: overlayScene_res[0].id,
                            ...overlayStep.params,
                        });

                        //RECREATE THE STEP OBJECT to use for localupdate
                        overlayStep.id = overlay_res_step[0].id;
                        overlayScene.addStep(overlayStep);
                    }

                    setScenes((prev) => [...prev, overlayScene]);
                    setSelectedScene(overlayScene);
                } catch (error) {
                    console.log("error :: ", error);
                }

                // setProject((prev) => ({
                //     ...prev,
                //     scenes: [...prev.scenes, overlayScene.getData()],
                // }));

                // setSelectedScene(overlayScene);
                // setSelectedDetail(null);
                break;

            case "selectAll":
                setScenes((prev) =>
                    prev.map((scene) => ({
                        ...scene,
                        active: scene?.result?.indexOf("https") > -1,
                    }))
                );
                break;
            default:
                break;
        }
    };

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <div className="scenes-container flex flex-row bg-white border border-lightblue100 rounded-2xl relative overflow-hidden">
                <div className="flex flex-1 flex-col gap-4 p-4 pb-0">
                    <div className="scenes-actions flex-1 px-1 min-h-8 max-h-8 flex flex-col items-start justify-center">
                        <div className="flex flex-row gap-8">
                            <div
                                className={`cursor-pointer text-black  hover:text-blue`}
                                onClick={async () => {
                                    //await handleScene({ opr: "add", project, scenes, setScenes, setSelectedScene, setSelectedDetail });
                                    setShowSceneAdd(true);
                                }}
                            >
                                Add Scene <span className="text-xs text-black/50">{`${project.width}x${project.height}`}</span>
                            </div>

                            <div
                                className={`cursor-pointer ${editMode ? "text-blue" : "text-black hover:text-blue"} `}
                                onClick={() => {
                                    setEditMode(!editMode);
                                }}
                            >
                                {editMode ? "Done" : "Edit"}
                            </div>

                            <div className="separator w-0.5 h-full border-r border-lightblue100"></div>

                            {activeSceneCount >= 2 && (
                                <div
                                    className={`cursor-pointer text-black  ${merging ? "opacity-30 cursor-not-allowed" : "hover:text-blue"}`}
                                    onClick={() => {
                                        if (!merging) {
                                            handleProject({ opr: "merge" });
                                        }
                                    }}
                                >
                                    {merging ? "Merging..." : "Merge"}
                                </div>
                            )}

                            {activeSceneCount >= 2 && (
                                <div
                                    className={`cursor-pointer text-black  ${merging ? "opacity-30 cursor-not-allowed" : "hover:text-blue"}`}
                                    onClick={() => {
                                        handleProject({ opr: "overlay" });
                                    }}
                                >
                                    {merging ? "Overlayin..." : "Overlay"}
                                </div>
                            )}

                            {/* {hasInactiveScenes && (
                                <div
                                    className={`cursor-pointer text-black  ${
                                        !hasActiveScenes || merging ? "opacity-30 cursor-not-allowed" : "hover:text-blue"
                                    }`}
                                    onClick={() => {
                                        handleProject({ opr: "selectAll" });
                                    }}
                                >
                                    Select All
                                </div>
                            )} */}
                        </div>
                    </div>

                    <div className="scenes-list flex-1 flex flex-row gap-4 relative min-h-[150px]">
                        <div className="absolute top-0 left-0 w-full h-full bg-white z-20 overflow-x-auto flex flex-row gap-4 pb-4">
                            <div className="flex flex-1 flex-row gap-4 relative">
                                {scenes?.length > 0 && (
                                    <SortableContext items={scenes?.map((scene) => scene.id)} strategy={horizontalListSortingStrategy}>
                                        {scenes?.map((scene) => (
                                            <Scene
                                                key={scene.id}
                                                scenes={scenes}
                                                setScenes={setScenes}
                                                scene={scene}
                                                setProject={setProject}
                                                project={project}
                                                selectedScene={selectedScene}
                                                setSelectedScene={setSelectedScene}
                                                selectedDetail={selectedDetail}
                                                setSelectedDetail={setSelectedDetail}
                                                editMode={editMode}
                                                stageSize={stageSize}
                                            />
                                        ))}
                                    </SortableContext>
                                )}
                                {scenes?.length <= 0 && (
                                    <div className="flex flex-col items-center justify-center gap-2 sticky right-0 bg-white min-w-32 z-20">
                                        <div
                                            className={`transition-all scene-video rounded-2xl overflow-hidden relative text-blue text-xl border border-dashed border-blue/50 flex items-center justify-center cursor-pointer hover:border-blue hover:bg-blue/10 text-black/30 hover:text-blue`}
                                            style={getSceneDimensions(project)}
                                            onClick={async () => {
                                                setShowSceneAdd(true);
                                                //await handleScene({ opr: "add", project, scenes, setScenes, setSelectedScene, setSelectedDetail });
                                            }}
                                        >
                                            +
                                        </div>
                                        <div className="scene-name text-xs text-center text-blue">Add Scene</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DndContext>
    );
};

export default Scenes;
