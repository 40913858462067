import React, { useRef, useState, useEffect } from "react";
import { Rect, Group, Image, Transformer } from "react-konva";
import Konva from "konva";
import { FaPlay, FaPause } from "react-icons/fa";
import { renderToString } from "react-dom/server";
import { detectMediaType } from "src/modules/admin/studio/utils";

const ImageLayer = (props) => {
    const { stageSize, detail, selectedDetail, setSelectedDetail, previewOnly, selectedScene, setSelectedScene } = props;
    const [image, setImage] = useState(null);
    const videoRef = useRef(null);
    const imageRef = useRef();
    const trRef = useRef();
    const layerRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const ASPECT_RATIO_TOLERANCE = 0.01;

  
    let mediaType = detail?.params?.src ? detectMediaType({detail, url: detail.params.src}) : null;

    useEffect(() => {
        // You can now use detectMediaType here if needed
      
        if (mediaType === "video") {
            // Create video element with same settings as preview.js
            const video = document.createElement("video");
            video.src = detail?.params?.use_nobg === true ? detail?.params?.src_nobg : detail?.params?.src;
            video.crossOrigin = "anonymous";
            video.loop = true;
            video.muted = previewOnly;
            video.playsInline = true;
            video.volume = 1.0;
            video.preload = "auto";
            if (!videoRef.current) {
                video.currentTime = 0.01;
            } else {
                video.currentTime = videoRef.current.currentTime;
            }
    
            // Save video reference
            videoRef.current = video;
    
            // Wait for metadata and dimensions before setting the image
            video.addEventListener("loadedmetadata", () => {
                if (video.videoWidth && video.videoHeight) {
                    setImage(video);
                }
            });

            // Cleanup
            return () => {
                video.pause();
                video.src = "";
                videoRef.current = null;
            };
        } else if (mediaType === "image") {
            // Handle static image
            const img = new window.Image();
            
            img.src = detail?.params?.use_nobg ? detail?.params?.src_nobg : detail?.params?.src;
            img.onload = () => {
                setImage(img);
            };
        }
    }, [detail.params.src, detail.params.use_nobg, detail.params.src_nobg, detail.type, previewOnly]);

    // Add animation frame for video
    useEffect(() => {
        if ((detail.type === "video" || detail.type === "avatar") && videoRef.current && imageRef.current) {
            const anim = new Konva.Animation((frame) => {
                // Need to update the layer to show video frames
                imageRef.current?.getLayer().batchDraw();
                return true;
            }, imageRef.current?.getLayer());

            anim.start();

            return () => {
                anim.stop();
            };
        }
    }, [detail.type, videoRef.current, imageRef.current]);

    // Function to resize the image to cover the group
    useEffect(() => {
        // Run when image changes or when dimensions need to be set
        console.log(`11 image ${selectedScene?.name} ${detail.action}::`, image, detail.params.x, detail.params.y, detail.params.width, detail.params.height);
        //if (image && (!selectedDetail || image.src === selectedDetail?.params.src)) {
        if (image) {

            
            const imgWidth = mediaType === "video" ? image.videoWidth : image.width;
            const imgHeight = mediaType === "video" ? image.videoHeight : image.height;

            const stageRatio = stageSize.width / stageSize.height;
            const imgRatio = imgWidth / imgHeight;

            let newWidth = detail.params.width;
            let newHeight = detail.params.height;
            let newX = detail.params.x;
            let newY = detail.params.y;

            
            const hasCorrectRatio = checkAspectRatio(
                newWidth,
                newHeight,
                mediaType === "video" ? image.videoWidth : image.width,
                mediaType === "video" ? image.videoHeight : image.height
            );


            // Check if dimensions need to be recalculated
            const shouldRecalculateDimensions = (value) => {
                return value === "null" || value === "0" || value === 0 || value === undefined || !hasCorrectRatio || value === NaN;
            };
            const shouldRecalculatePosition = (value) => {
                return value === "null" || value === "0" || value === undefined || value === NaN;
            };

            //WIDTH AND HEIGHT
            if (detail.action === "generate-video") {
                //CONTAIN
                if (imgRatio > stageRatio) {
                    // Image is wider than the stage, scale based on width
                    newWidth = shouldRecalculateDimensions(newWidth) ? stageSize.width : newWidth;
                    newHeight = shouldRecalculateDimensions(newHeight) ? (stageSize.width / imgWidth) * imgHeight : newHeight;
                    
                } else {
                    // Image is taller than the stage, scale based on height
                    newHeight = shouldRecalculateDimensions(newHeight) ? stageSize.height : newHeight;
                    newWidth = shouldRecalculateDimensions(newWidth) ? (stageSize.height / imgHeight) * imgWidth : newWidth;
                }
            } else if (detail.action === "realestate-first-page" || detail.action === "realestate-last-page") {
                //COVER
                if (imgRatio > stageRatio) {
                    // Image is wider, scale based on height
                    newHeight = stageSize.height;
                    newWidth = (stageSize.height / imgHeight) * imgWidth;
                } else {
                    // Image is taller, scale based on width
                    newWidth = stageSize.width;
                    newHeight = (stageSize.width / imgWidth) * imgHeight;
                }
            } else {
                //COVER
                
                if (imgRatio > stageRatio) {
                    // Image is wider, scale based on height
                    newHeight = shouldRecalculateDimensions(newHeight) ? stageSize.height : newHeight;
                    newWidth = shouldRecalculateDimensions(newWidth) ? (stageSize.height / imgHeight) * imgWidth : newWidth;
                } else {
                    // Image is taller, scale based on width
                    newWidth = shouldRecalculateDimensions(newWidth) ? stageSize.width : newWidth;
                    newHeight = shouldRecalculateDimensions(newHeight) ? (stageSize.width / imgWidth) * imgHeight : newHeight;
                }
            }
            // X AND Y

            if (detail.action === "realestate-first-page" || detail.action === "realestate-last-page") {
                newX = (stageSize.width - newWidth) / 2;
                newY = (stageSize.height - newHeight) / 2;
            } else {

                // Center the image if coordinates are 0 or numeric zero
                newX = shouldRecalculatePosition(newX) ? (stageSize.width - newWidth) / 2 : newX;
                newY = shouldRecalculatePosition(newY) ? (stageSize.height - newHeight) / 2 : newY;
            }

            console.log(`22 image ${selectedScene?.name}  ${detail.action}::`, image, newX, newY, newWidth, newHeight);

            updateDetail({
                newWidth: newWidth,
                newHeight: newHeight,
                newX: newX,
                newY: newY,
            });
        }
    }, [image, selectedDetail?.params?.src]);

    useEffect(() => {
        if (selectedDetail?.id === detail.id && trRef.current && imageRef.current) {
            trRef.current.nodes([imageRef.current]);
            trRef.current.getLayer().batchDraw();

            // const node = imageRef.current;
            // updateSelectedScene({ node });
        }
    }, [selectedDetail]);

    const handleSelect = () => {
        if (setSelectedDetail && !previewOnly) {
            setSelectedDetail({ ...detail });
        }
    };

    const updateDetail = ({ node, newX, newY, newWidth, newHeight }) => {
        if (!node && !newWidth && !newHeight) return;

        // Get the current node if not provided
        const currentNode = node || imageRef.current;

        // Calculate final dimensions
        const width = newWidth || currentNode.width() * currentNode.scaleX();
        const height = newHeight || currentNode.height() * currentNode.scaleY();
        const x = newX ?? currentNode.x();
        const y = newY ?? currentNode.y();

        // IF HAS SELECTED DETAIL
        if (selectedDetail && selectedDetail?.id === detail.id) {
            //
            setSelectedDetail((prev) => {
                return {
                    ...prev,
                    params: {
                        ...prev.params,
                        x,
                        y,
                        width,
                        height,
                    },
                };
            });
        } else {
            //FOR LOADING SCENE BOTTOM VIEW
            
            setSelectedScene((prev) => {
                if (prev?.steps) {
                    return {
                        ...prev,
                        steps: prev.steps.map((step) => {
                            return step.id === detail.id ? { ...step, params: { ...step.params, x, y, width, height } } : step;
                        }),
                    };
                }
                return prev;
            });
        }

        // Reset scale after updating dimensions
        if (currentNode) {
            currentNode.width(width);
            currentNode.height(height);
            currentNode.scaleX(1);
            currentNode.scaleY(1);
        }
    };

    const handlePlayPause = () => {
        if (!videoRef.current) return;

        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play().catch(console.error);
        }
        setIsPlaying(!isPlaying);
    };

    const checkAspectRatio = (currentWidth, currentHeight, originalWidth, originalHeight) => {
        const currentRatio = currentWidth / currentHeight;
        const originalRatio = originalWidth / originalHeight;
        return Math.abs(currentRatio - originalRatio) <= ASPECT_RATIO_TOLERANCE;
    };

    return (
        <Group ref={layerRef}>
            {image && (
                <Image
                    image={image}
                    x={parseFloat(detail.params.x)}
                    y={parseFloat(detail.params.y)}
                    width={parseFloat(detail.params.width)}
                    height={parseFloat(detail.params.height)}
                    ref={imageRef}
                    draggable={!previewOnly}
                    onMouseDown={(e) => {
                        // Prevent click when dragging
                        if (isDragging) {
                            return;
                        }
                        handleSelect();
                    }}
                    onDragStart={() => setIsDragging(true)}
                    onDragEnd={(e) => {
                        const node = e.target;
                        updateDetail({ node });
                        setIsDragging(false);
                    }}
                    onTransformStart={(e) => {
                        setIsDragging(true);
                    }}
                    onTransformEnd={(e) => {
                        setIsDragging(false);
                        const node = imageRef.current;
                        updateDetail({ node });
                    }}
                    filters={detail.params.use_nobg ? [Konva.Filters.ChromaKey] : []}
                    chromaKey={[51, 213, 1]}
                    chromaSimilarity={0.4}
                    chromaSmoothness={0.1}
                />
            )}

            {/* Only show controls when not dragging */}
            {detail.type === "video" ||
                (detail.type === "avatar" && !previewOnly && !isDragging && image && (
                    <>
                        <Rect
                            x={parseFloat(detail.params.x) + 10}
                            y={parseFloat(detail.params.y) + parseFloat(detail.params.height) - 30}
                            width={20}
                            height={20}
                            fill="rgba(0, 0, 0, 0.5)"
                            cornerRadius={5}
                            onClick={handlePlayPause}
                            onTap={handlePlayPause}
                            cursor="pointer"
                        />
                        <Image
                            x={parseFloat(detail.params.x) + 15}
                            y={parseFloat(detail.params.y) + parseFloat(detail.params.height) - 25}
                            width={10}
                            height={10}
                            image={(() => {
                                const icon = isPlaying ? FaPause : FaPlay;
                                const svg = icon({
                                    size: "20px",
                                    color: "white",
                                });
                                const img = new window.Image();
                                img.src = `data:image/svg+xml;base64,${btoa(renderToString(svg))}`;
                                return img;
                            })()}
                            onClick={handlePlayPause}
                            onTap={handlePlayPause}
                            cursor="pointer"
                        />
                    </>
                ))}

            {/* Existing Transformer code */}
            {selectedDetail?.id === detail.id && image && !previewOnly && (
                <Transformer
                    ref={trRef}
                    anchorStroke={`#6676FF`}
                    borderStroke={`#6676FF`}
                    borderStrokeWidth={2}
                    anchorStrokeWidth={2}
                    rotateEnabled={false}
                    enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 50 || newBox.height < 50) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Group>
    );
};

export default ImageLayer;
