import { useParams, useNavigate } from "react-router-dom";
import AdminLayout from "src/layouts/admin-layout";
import { useEffect, useState } from "react";
import DirectoryAvatars from "src/modules/admin/avatars/directory-avatars";
import { FaChevronRight } from "react-icons/fa";
import ListAvatars from "src/modules/admin/avatars/list-avatars";
import { IoAddCircleOutline } from "react-icons/io5";

const AvatarVideos = (props) => {
 
    const navigate = useNavigate();
    const params = useParams();
    const [action, setAction] = useState();
  //  const [selectedDirectory, setSelectedDirectory] = useState(null);
    const [isUploading, setIsUploading] = useState(false);


    const selectedDirectory = params.directory;

    return (
        <>
            <AdminLayout
                header={
                    <div className="flex flex-1 flex-col ">
                        <div className="flex flex-1 flex-row px-0 items-center">
                            <div className="flex items-center gap-5">
                                <div className="text-lg md:text-xl font-semibold flex flex-row items-center gap-4">
                                    <span
                                        className={selectedDirectory ? `text-black/30 cursor-pointer` : `text-black`}
                                        onClick={() => navigate("/admin/avatars")}
                                    >
                                        Your Avatars
                                    </span>
                                    {selectedDirectory ? <FaChevronRight className="w-4 h-4" /> : ""} {selectedDirectory}
                                </div>

                                <label
                                    className={`relative overflow-hidden transition-all bg-white border rounded-full p-2 pr-5 flex items-center gap-2 
                                    ${
                                        isUploading
                                            ? "text-blue border-blue"
                                            : "opacity-60 hover:opacity-100 hover:text-blue hover:border-blue border-zinc-500/40 cursor-pointer"
                                    }
                                `}
                                >
                                    {!isUploading ? (
                                        <>
                                            <IoAddCircleOutline className="text-xl" />
                                            <div>Upload</div>
                                        </>
                                    ) : (
                                        <div className="loading pl-2">Uploading</div>
                                    )}

                                    {!isUploading && (
                                        <input
                                            type="file"
                                            title=""
                                            accept={`video/*`}
                                            className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                                            onChange={async (e) => {
                                                //console.log(e.target.files[0]);
                                                setAction({ type: "upload", file: e.target.files[0] });
                                                setIsUploading(true);
                                            }}
                                        />
                                    )}
                                </label>
                            </div>
                        </div>
                    </div>
                }
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <ListAvatars
                        action={action}
                        selectedDirectory={selectedDirectory}
                        OnUploaded={() => {
                            setIsUploading(false);
                        }}
                        OnError={() => {
                            setIsUploading(false);
                        }}
                    />
                </div>
            </AdminLayout>
        </>
    );
};

export default AvatarVideos;
