import { useEffect, useState, useRef } from "react";
import { TbAlertCircle } from "react-icons/tb";
import Dbox from "src/common/components/dbox/dbox";
import { LuBrainCog } from "react-icons/lu";
import UseLLM from "./use-llm";

const AddText = (props) => {
    const { genData } = props;
    const textareaRef = useRef(null);
    const MAX_CHARS = 1000;
    const [showLLM, setShowLLM] = useState(false);

    useEffect(() => {
        // Auto focus the textarea and move cursor to end
        if (textareaRef.current) {
            textareaRef.current.focus();
            const length = textareaRef.current.value.length;
            textareaRef.current.setSelectionRange(length, length);
        }
    }, []);

    const [defText, setDefText] = useState(null);
    let storedData = { ...genData };

    return (
        <div className="flex-1 flex flex-col gap-4  md: gap-8 pb-6">
            {/* <div className="flex flex-row items-start gap-6">
                
                <div className="flex flex-col gap-1">
                    <div className="text-xl font-semibold">Add Your Script</div>
                    <div className="opacity-50">Add text that your video will use. This will be converted as speech.</div>
                </div>
            </div> */}

            <div className="flex flex-1 flex-row gap-5">
                <Dbox className={`relative  overflow-hidden flex-1`}>
                    <div className="flex flex-1 flex-row gap-10 p-4 md:p-10">
                        <div className="flex-1 flex flex-col gap-8">
                            {/* <div>Add some instructions here...</div> */}
                            <div className="border flex flex-col  flex-1 border-lightblue100 rounded-2xl relative overflow-hidden bg-lightblue50">
                                <textarea
                                    ref={textareaRef}
                                    value={genData.text}
                                    className="flex flex-1 w-full p-8"
                                    rows={8}
                                    maxLength={MAX_CHARS}
                                    placeholder={`Add text for you audio ...`}
                                    onChange={(e) => {
                                        genData.text = e.target.value;
                                        props.OnSet({ ...genData });
                                    }}
                                />
                                <div className="flex flex-col gap-2 p-3">
                                    <UseLLM genData={genData} OnSet={props.OnSet} />
                                </div>
                            </div>

                          

                            <div className="flex gap-2 items-start ">
                                <div>
                                    <TbAlertCircle className="text-lg text-amber-400" />
                                </div>
                                <span className="opacity-40">For increased realism in your avatar script, please ensure proper punctuation is used.</span>
                            </div>
                        </div>
                    </div>
                </Dbox>
            </div>
        </div>
    );
};

export default AddText;
