import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CardGen from "src/modules/admin/generated-videos/card-gen";
import OnBottom from "src/common/components/on-bottom/on-bottom";
import Preloader from "src/common/components/preloader/preloader";
import { getVideos } from "src/common/services/generate";
import { MdArrowForwardIos } from "react-icons/md";
import { getGeneratedAvatars } from "src/common/services/avatars";

const ListGen = (props) => {
    const { vtype } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [videos, setVideos] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [fetching, setFetching] = useState(null);
    const [onUse, setOnUse] = useState(null);

    let colClass = `grid gap-6 grid-cols-1 justify-between ${props.cols ? `grid-cols-${props.cols}` : `grid-cols-6`}`;

    if (props.coltype === "flex") {
        colClass = `flex flex-1 flex-row gap-6 flex-nowrap z-40`;
    }

    useEffect(() => {
        handleVideos({ opr: "list", pageNum: 1 });
    }, [location]);

    useEffect(() => {
        if (props.OnUse && onUse) {
            props.OnUse({ ...onUse });
        }
    }, [onUse]);

    const handleVideos = async ({ opr, pageNum }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                try {
                  
                    const list_res = await getGeneratedAvatars();
                    console.log(`list_res ${pageNum}:: `, list_res);
                    setVideos(list_res);
                    // if (location?.state?.reload) {
                    //     setVideos([]);
                    //     await new Promise((res) => setTimeout(() => res(), 10));
                    //     navigate(location.pathname);
                    // }

                    // if (list_res && pageNum > 1) {
                    //     setVideos([...videos, ...list_res]);
                    // } else {
                    //     setVideos([...list_res]);
                    // }

                    // if (list_res.length > 0) {
                    //     setPageNum(pageNum + 1);
                    // }
                } catch (error) {
                    console.log(error);
                }

                break;
            default:
                break;
        }
        setFetching(false);
    };

    const containerRef = useRef(null);
    const videosRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(true);

    useEffect(() => {
        console.log("scrollPosition: ", scrollPosition);
        containerRef.current.scrollLeft = scrollPosition;
    }, [scrollPosition]);

    const smoothScroll = (start, end, duration) => {
        const startTime = performance.now();
        
        const animate = (currentTime) => {
            const elapsed = currentTime - startTime;
            const progress = Math.min(elapsed / duration, 1);
            
            // Easing function for smoother animation
            const easeInOutCubic = progress => 
                progress < 0.5
                    ? 4 * progress * progress * progress
                    : 1 - Math.pow(-2 * progress + 2, 3) / 2;
            
            const currentPosition = start + (end - start) * easeInOutCubic(progress);
            containerRef.current.scrollLeft = currentPosition;
            
            if (progress < 1) {
                requestAnimationFrame(animate);
            }
        };
        
        requestAnimationFrame(animate);
    };

    const handleScroll = (direction) => {
        if (props.coltype === "flex" && containerRef.current) {
            const container = containerRef.current;
            const scrollAmount = 1200;
            const newPosition = direction === 'right' 
                ? scrollPosition + scrollAmount 
                : scrollPosition - scrollAmount;

            const maxScroll = container.scrollWidth - container.clientWidth;
            const finalPosition = Math.max(0, Math.min(newPosition, maxScroll));

            setShowLeftArrow(finalPosition > 0);
            setShowRightArrow(finalPosition < maxScroll);

            // Custom smooth scroll animation
            smoothScroll(container.scrollLeft, finalPosition, 500); // 500ms duration
            setScrollPosition(finalPosition);
        }
    };

    // Add scroll event listener to update arrow visibility
    useEffect(() => {
        const container = containerRef.current;

        if (videosRef.current && props.coltype === "flex") {
            const handleScrollEvent = () => {
                const maxScroll = videosRef.current.scrollWidth - videosRef.current.clientWidth;
                console.log(videosRef.current.scrollWidth);
                setShowLeftArrow(videosRef.current.scrollLeft > 0);
                setShowRightArrow(videosRef.current.scrollLeft < maxScroll);
            };
            
            container.addEventListener('scroll', handleScrollEvent);
            handleScrollEvent(); // Initial check
            
            return () => container.removeEventListener('scroll', handleScrollEvent);
        }
    }, []);

    return (
        <>
            {showLeftArrow && props.coltype === "flex" && (
                <div
                    className="absolute top-1/2 -translate-y-1/2 left-4 w-[60px] h-[60px] bg-white/20 rounded-full flex items-center justify-center z-50 cursor-pointer hover:bg-white/90 transition-all duration-300 ease-in-out"
                    onClick={() => handleScroll('left')}
                >
                    <MdArrowForwardIos className="w-8 h-8 text-black rotate-180" />
                </div>
            )}
            
            {showRightArrow && props.coltype === "flex" && (
                <div
                    className="absolute top-1/2 -translate-y-1/2 right-4 w-[60px] h-[60px] bg-white/20 rounded-full flex items-center justify-center z-50 cursor-pointer hover:bg-white/90 transition-all duration-300 ease-in-out"
                    onClick={() => handleScroll('right')}
                >
                    <MdArrowForwardIos className="w-8 h-8 text-black" />
                </div>
            )}

            <div
                ref={containerRef}
                className={`list-gen-container relative slideFromTop flex-1 flex flex-col ${props.coltype === "flex" ? "relative overflow-x-hidden" : ""}`}
            >
                {((fetching && pageNum === 1) || (fetching && props.paging === "manual")) && <Preloader />}

                <OnBottom
                    OnBottom={async (val) => {
                        if (pageNum > 1 && props.paging !== "manual") {
                            await handleVideos({ opr: "list", pageNum });
                        }
                    }}
                >
                    {videos?.length > 0 && (
                        <div ref={videosRef} className={`${colClass}`}>
                            {videos.map((video, v) => (
                                <CardGen
                                    {...props}
                                    key={v}
                                    video={video}
                                    OnUse={(video) => {
                                        setOnUse(video);
                                    }}
                                    OnDelete={async () => {
                                        await handleVideos({ opr: "list", pageNum: 1 });
                                    }}
                                />
                            ))}
                        </div>
                    )}
                    {!fetching && props.paging === "manual" && videos?.length > 19 && (
                        <div
                            className="p-3 cursor-pointer mt-6 flex items-center justify-center border border-lightblue100 text-black/50 rounded-xl hover:text-black/100 hover:bg-lightblue100/30"
                            onClick={async () => {
                                await handleVideos({ opr: "list", pageNum });
                            }}
                        >
                            Load more
                        </div>
                    )}
                </OnBottom>
                {videos?.length <= 0 && fetching !== null && vtype !== "public" && (
                    <div className="flex h-full flex-1 items-center justify-center">
                        <div className="flex flex-col gap-4 items-center">
                            {/* <div className="border-2 border-indigo-500/10 rounded-full w-28 h-28 flex items-center justify-center mb-6"><CiImageOff className="text-6xl text-indigo-500/20 " /></div> */}
                            You have not generate any videos yet.
                            <button
                                className="button button-small px-4"
                                onClick={() => {
                                    navigate(`/admin/generate-video`, { state: { todo: "create" } });
                                }}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                )}
                {videos?.length <= 0 && fetching !== null && vtype === "public" && (
                    <div className="flex h-full flex-1 items-center justify-center">No trending videos yet.</div>
                )}
            </div>
            {/* <div></div> */}
        </>
    );
};

export default ListGen;
