import UiDSection from "../../components/ui-dsection";
import UiSelect from "../../components/ui-select";
import UiColorPicker from "../../components/ui-color-picker";
import { MdOutlineEdit } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; 
import { updateProject } from "src/common/services/project";

const Project = (props) => {
    const navigate = useNavigate();
    const { screens, project, setProject } = props;
    const [saving, setSaving] = useState(false);

    const getSizeOptions = (proportion) => {
        switch (proportion) {
            case "16:9":
                return [
                    { value: { width: "1280", height: "720" }, label: "1280 x 720" },
                    { value: { width: "1920", height: "1080" }, label: "1920 x 1080" },
                    { value: { width: "2560", height: "1440" }, label: "2560 x 1440" },
                ];
            case "9:16":
                return [
                    { value: { width: "720", height: "1280" }, label: "720 x 1280" },
                    { value: { width: "1080", height: "1920" }, label: "1080 x 1920" },
                    { value: { width: "1440", height: "2560" }, label: "1440 x 2560" },
                ];
            case "4:3":
                return [
                    { value: { width: "800", height: "600" }, label: "800 x 600" },
                    { value: { width: "1024", height: "768" }, label: "1024 x 768" },
                    { value: { width: "1600", height: "1200" }, label: "1600 x 1200" },
                ];
            default:
                return [];
        }
    };

    // Get current size options based on selected proportion
    const sizeOptions = getSizeOptions(project.proportion);

    const handleProportionChange = (value) => {
        const defaultSize = getSizeOptions(value)[0]?.value || { width: "1280", height: "720" };
        setProject((prev) => ({
            ...prev,
            proportion: value,
            width: defaultSize.width,
            height: defaultSize.height,
        }));
    };

    const handleProject = async ({ opr }) => {
        setSaving(true);
        try {
            switch (opr) {
                case "update":
                    const update_res = await updateProject({ id: project.id, input: {
                        name: project.name,
                        width: project.width,
                        height: project.height,
                        proportion: project.proportion,
                    } });
                    console.log("projectupdate_res :: ", update_res);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.log("handleProject error :: ", error);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="scene-detail flex-1 flex flex-col w-full">
            <div className=" flex-1 flex flex-col">
                <UiDSection>
                    <div className="flex flex-row gap-2 items-center">
                        <div
                            className="cursor-pointer opacity-50 hover:opacity-100 -translate-x-2"
                            onClick={() => {
                               navigate("/admin/studios");
                            }}
                        >
                            <IoIosArrowBack className="text-md" />
                        </div>
                        <input
                            type="text"
                            className="!p-0  w-full"
                            placeholder={`Add Project Name`}
                            value={project.name || ""}
                            onChange={(e) => setProject((prev) => ({ ...prev, name: e.target.value }))}
                        />
                        <MdOutlineEdit className="text-lg opacity-40 cursor-pointer" />
                    </div>
                </UiDSection>

                <UiDSection title="Layout">
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-row items-center justify-between gap-3">
                            <div className="flex-1">Proportion:</div>
                            <div className="flex-1 flex flex-row items-center justify-between gap-2">
                                {screens.map((screen, index) => (
                                    <div
                                        key={index}
                                        className={`flex items-center justify-center p-3 cursor-pointer rounded-xl
                                       
                                        ${project.proportion === screen.value ? "bg-lightblue100/50" : "text-black/30 hover:bg-lightblue100/20"}`}
                                        onClick={() => handleProportionChange(screen.value)}
                                        title={screen.label}
                                    >
                                        {screen.icon}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="flex flex-row items-center justify-between gap-3">
                            <div className="flex-1">Size :</div>

                            <UiSelect
                                className="flex-1"
                                value={{ width: project.width.toString(), height: project.height.toString() }}
                                options={sizeOptions}
                                onChange={(value) => 
                                    setProject((prev) => ({
                                        ...prev,
                                        width: value.width,
                                        height: value.height,
                                    }))
                                }
                            />
                        </div>
                    </div>
                </UiDSection>

                {/* <UiDSection title="Design">
                    <UiColorPicker title="Background" value={project.background} setValue={(val) => handleProject({ opr: "update", input: { background: val } })} />
                </UiDSection> */}
            </div>

            <div className="p-5" onClick={async () => {
                if(saving) return;
                await handleProject({ opr: "update" });
            }}>
                <button className="button px-4 py-4 rounded-xl w-full">
                    {saving ? "Saving..." : "Save Project"}
                </button>
            </div>
        </div>
    );
};

export default Project;
