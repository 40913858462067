import { useState, useRef, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import { GrUploadOption } from "react-icons/gr";

import ListGen from "../../../generated-videos/list-gen";
import ListVideos from "../../../inputed-videos/list-videos";
import ListImages from "../../../inputed-images/list-images";

const MediaSelect = (props) => {
    const { type } = props;
    const [search, setSearch] = useState("");
    const fileInputRef = useRef(null);
    const [urlInput, setUrlInput] = useState("");
    const [urlError, setUrlError] = useState("");

    const initialTabs = [
        {
            id: "generated",
            label: "Avatars",
            type: "video",
        },
        {
            id: "input-videos",
            label: "Input Videos",
            type: "video",
        },
        {
            id: "input-images",
            label: "Input Images",
            type: "image",
        },
        {
            id: "url",
            label: "URL",
            type: "url",
        },
        {
            id: "upload",
            label: "Upload",
            type: "upload",
        },
    ];

    const [tabs, setTabs] = useState(initialTabs);

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    useEffect(() => {
        if (type) {
           
            setTabs(
                tabs.filter((tab) => {
                    return type.includes(tab.type) || tab.id === "upload" || tab.id === "url";
                })
            );
        } else {
            setTabs(initialTabs);
        }
    }, [type]);

    useEffect(() => {
        setSelectedTab(tabs[0]);
    }, [tabs]);

    useEffect(() => {
        if (selectedTab.id === "upload") {
            //fileInputRef.current?.click();
        }
    }, [selectedTab]);

    const handleSelectInput = (input) => {
        // console.log(input);

        // const mediaParams = {
        //     src: "null"
        //     // file: "null",
        // };

        // //NORMALIZE INPUT
        // if (input.result) {
        //     mediaParams.src = input.result;
        // } else if (input.url) {
        //     mediaParams.src = input.url;
        // } else if (input.constructor.name === "File") {
        //     mediaParams.src = URL.createObjectURL(input);
        //     mediaParams.file = input;
        // }

        // //    mediaParams.type = type;
        // console.log("mediaParams ::", mediaParams);
        if (props.OnSelect) {
            props.OnSelect(input);
        }
    };

    const validateMediaUrl = (url) => {
        return new Promise((resolve) => {
            // First try as image
            const img = new Image();
            img.onload = () => resolve("image");
            img.onerror = () => {
                // If not image, try as video
                const video = document.createElement("video");
                video.onloadedmetadata = () => resolve("video");
                video.onerror = () => resolve(null);
                video.src = url;
            };
            img.src = url;
        });
    };

    const handleUrlSubmit = async (e) => {
        e.preventDefault();
        setUrlError("");

        if (!urlInput.trim()) {
            setUrlError("Please enter a URL");
            return;
        }

        const mediaType = await validateMediaUrl(urlInput);

        if (!mediaType) {
            setUrlError("Invalid media URL. Please enter a valid image or video URL");
            return;
        }

        if (type && mediaType !== type) {
            setUrlError(`Please enter a ${type} URL`);
            return;
        }

        handleSelectInput({ url: urlInput, type: mediaType });
    };

    return (
        <div className="flex flex-col min-h-600 max-h-600 relative overflow-hidden">
            <div className="flex flex-col p-10 gap-8">
                <div className=" text-lg capitalize">Select {type?.replace(/[/*]/g, "").replace(/,/g, " / ")}</div>
                <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2 ">
                    {tabs.map((tab, t) => (
                        <div
                            key={t}
                            className={`transition flex-1 cursor-pointer ${tab.id === selectedTab.id ? "opacity-100 bg-white rounded-xl py-2" : "opacity-50"}`}
                            onClick={() => {
                                setSelectedTab({ ...tab });
                            }}
                        >
                            <div className="">{tab.label}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex  flex-1 flex-col gap-4  relative overflow-y-auto p-10 pt-0">
                {selectedTab?.id === "generated" && (
                    <ListGen
                        cols={4}
                        thumbsize={"300"}
                        // selectedId={theVideo.avatar.video?.id}
                        for={`select`}
                        paging={"manual"}
                        OnUse={(video) => {
                            handleSelectInput(video);
                        }}
                    />
                )}

                {selectedTab?.id === "input-videos" && (
                    <ListVideos
                        cols={4}
                        thumbsize={"300"}
                        // selectedId={theVideo.avatar.video?.id}
                        for={`select`}
                        paging={"manual"}
                        OnUse={(video) => {
                            handleSelectInput(video);
                        }}
                    />
                )}

                {selectedTab?.id === "input-images" && (
                    <ListImages
                        cols={4}
                        thumbsize={"300"}
                        // selectedId={theVideo.avatar.video?.id}
                        for={`select`}
                        paging={"manual"}
                        OnUse={(video) => {
                            handleSelectInput(video);
                        }}
                    />
                )}

                {selectedTab?.id === "url" && (
                    <div className="flex-1 flex flex-col items-center justify-center">
                        <form onSubmit={handleUrlSubmit} className="w-full ">
                            <div className="border w-full border-lightblue100 rounded-2xl p-2 flex flex-row items-center justify-center">
                                <input
                                    type="text"
                                    placeholder="Enter URL"
                                    value={urlInput}
                                    onChange={(e) => setUrlInput(e.target.value)}
                                    className="w-full outline-none flex-1"
                                />
                                <button type="submit" className=" button button-small bg-blue p-2 px-5 mr-2 rounded-2xl cursor-pointer">
                                    Add URL
                                </button>
                            </div>
                            {urlError && <div className="text-red-500 text-sm mt-2">{urlError}</div>}
                        </form>
                    </div>
                )}

                {selectedTab?.id === "upload" && (
                    <div className="flex-1 flex flex-col items-center justify-center">
                        <div className="transition min-h-full min-w-full flex-1 flex items-center gap-2 p-3 group border border-dashed border-indigo-800/30 rounded-2xl cursor-pointer hover:border-blue bg-blue/10 text-black/30 hover:text-blue items-center justify-center">
                            <button
                                className="relative z-10 button-white p-2 pr-5 rounded-3xl cursor-pointer flex items-center gap-2 capitalize"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <GrUploadOption className="text-md" />
                                Upload Media
                            </button>
                        </div>

                        <input
                            type="file"
                            ref={fileInputRef}
                            id="mediaInput"
                            className="hidden"
                            accept={`${type}/*`}
                            onChange={(e) => {
                                handleSelectInput(e.target.files[0]);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MediaSelect;
