const createSnowflake = () => {
    const snowflake = document.createElement('div');
    snowflake.classList.add('snowflake');
    
    // Random starting position and properties
    snowflake.style.left = Math.random() * 100 + 'vw';
    snowflake.style.opacity = Math.random() * 0.7 + 0.3; // Between 0.3 and 1
    
    // Random size between 4px and 12px
    const size = Math.random() * 8 + 4;
    snowflake.style.width = `${size}px`;
    snowflake.style.height = `${size}px`;
    
    // Increased base duration and multiplier for slower falling
    const duration = (12 - size) * 1.2 + 8; // Increased from 0.7 to 1.2, and base from 4 to 8
    snowflake.style.animation = `fall ${duration}s linear forwards`;
    
    return snowflake;
};

export const startSnowfall = () => {
    const container = document.createElement('div');
    container.classList.add('snowfall-container');
    document.body.appendChild(container);

    const MAX_SNOWFLAKES = 50; // Maximum number of snowflakes allowed

    const generateSnowflake = () => {
        // Check if we've reached the maximum
        if (container.children.length >= MAX_SNOWFLAKES) {
            return;
        }

        const snowflake = createSnowflake();
        container.appendChild(snowflake);

        // Remove snowflake after animation ends
        snowflake.addEventListener('animationend', () => {
            snowflake.remove();
        });
    };

    // Create new snowflakes more frequently
    setInterval(generateSnowflake, 150);
}; 