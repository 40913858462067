import { useParams, useNavigate } from "react-router-dom";
import AdminLayout from "src/layouts/admin-layout";
import { useEffect, useState } from "react";
import DirectoryAvatars from "src/modules/admin/avatars/directory-avatars";


const Avatars = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const [selectedDirectory, setSelectedDirectory] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const [showCreatePopup, setShowCreatePopup] = useState(false);

    useEffect(() => {
        if (params.id) {
            setSelectedDirectory(params.id);
        }
    }, [params.id]);

    return (
        <>
            <AdminLayout
                header={
                    <div className="flex flex-1 flex-col ">
                        <div className="flex flex-1 flex-row px-0 items-center">
                            <div className="flex items-center gap-5">
                                <div className="text-lg md:text-xl font-semibold flex flex-row items-center gap-4">
                                    <span>
                                        Your Avatars
                                    </span>
                                </div>

                                <label className={`relative overflow-hidden transition-all bg-white border rounded-full p-2 px-5 flex items-center gap-2 `}>
                                    <div className="" onClick={() => setShowCreatePopup(true)}>
                                        Add Avatar
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                }
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <DirectoryAvatars
                        showCreatePopup={showCreatePopup}
                        setShowCreatePopup={setShowCreatePopup}
                        selectedDirectory={selectedDirectory}
                        setSelectedDirectory={setSelectedDirectory}
                    />
                </div>
            </AdminLayout>
        </>
    );
};

export default Avatars;
