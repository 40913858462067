import { useState, useRef, useEffect } from "react";
import { formatStepType } from "src/modules/admin/studio/studio-funcs";

import Position from "src/modules/admin/studio/sidebar-detail/components/position";
import Fit from "src/modules/admin/studio/sidebar-detail/components/fit";
import Font from "src/modules/admin/studio/sidebar-detail/components/font";
import UiDSection from "src/modules/admin/studio/components/ui-dsection";
import Media from "src/modules/admin/studio/sidebar-detail/components/media";
import Audio from "src/modules/admin/studio/sidebar-detail/components/audio";
import RangeSlider from "src/modules/admin/studio/sidebar-detail/components/range-slider";
import Effect from "src/modules/admin/studio/sidebar-detail/components/effect";
import UiSelect from "../components/ui-select";
import Avatar from "./components/avatar";
import Subtitle from "./components/subtitle";
import Scenes from "./components/scenes";
import VideoPlayer from "src/common/components/video-player/video-player";
import { FaRegTrashAlt } from "react-icons/fa";
import Option from "./components/option";
import Range from "./components/range";
import Knob from "src/common/components/form/knob";
import Enhancement from "./components/enhancement";
import RealEstateFirst from "./components/real-estate-first";
import RealEstateLast from "./components/real-estate-last";
import Transition from "./components/transition";
import { handleStep } from "../handlers/step-handler";

const SidebarDetail = (props) => {
    const { project, setProject, selectedDetail, setSelectedDetail, stageSize, selectedScene, setSelectedScene, setShowInputAdd } = props;

    // Parse params if they're in string format

    // const parsedParams = selectedDetail?.params ? (typeof selectedDetail.params === "string" ? JSON.parse(selectedDetail.params) : selectedDetail.params) : {};

    const parsedParams = selectedDetail?.params;
    //    console.log("parsedParams :: ", parsedParams);
    const handleMediaType = (selectedDetail) => {
        switch (selectedDetail.action) {
            case "image2video":
            case "realestate-first-page":
            case "realestate-last-page":
            case "slide":
                return "image/*";
            case "subtitles":
                return "video/*, audio/*";
            default:
                return selectedDetail.type + "/*";
        }
    };

    return (
        <div className="sidebar-detail bg-white border border-lightblue100 rounded-2xl w-300 relative flex flex-col">
            <div className="flex flex-col relative flex-1">
                {/* {selectedDetail?.name && (
                    <div className="step-name p-5 border-b border-lightblue100/80 flex items-center justify-between gap-2 capitalize">
                        {selectedDetail?.name ? selectedDetail.name : formatStepType(selectedDetail?.action)}
                        {selectedDetail.detailType === "steps" && (
                            <FaRegTrashAlt
                                className="text-lg w-4 h-4 text-black opacity-50 hover:opacity-100 cursor-pointer"
                                onClick={() => handleStep({ opr: "delete", params: selectedDetail })}
                            />
                        )}
                    </div>
                )} */}

                {/* Loop through all fields */}
                <div className="flex-1 flex flex-col relative h-full overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full overflow-y-auto overflow-x-hidden flex flex-col">
                        {selectedDetail && (
                            <>
                                <div className="flex-1 flex flex-col relative">
                                    {selectedDetail.action === "generate-video" && (
                                        <Avatar
                                            setShowInputAdd={setShowInputAdd}
                                            selectedScene={selectedScene}
                                            selectedDetail={selectedDetail}
                                            setSelectedDetail={setSelectedDetail}
                                        />
                                    )}

                                    {(parsedParams.src || parsedParams.src === "") &&
                                        selectedDetail.type !== "audio" &&
                                        (selectedDetail.action === "overlay-video" ||
                                            selectedDetail.action === "overlay-image" ||
                                            selectedDetail.action === "realestate-first-page" ||
                                            selectedDetail.action === "realestate-last-page" ||
                                            selectedDetail.action === "image2video" ||
                                            //  (selectedDetail.action === "subtitles" && selectedScene?.steps?.[0]?.id === selectedDetail.id) ||
                                            selectedDetail.action === "subtitles" ||
                                            selectedDetail.action === "slide") && (
                                            <UiDSection>
                                                <Media
                                                    showMediaSelect={parsedParams.src === "null" || parsedParams.src === "" ? true : false}
                                                    valueObj={parsedParams}
                                                    mediaType={handleMediaType(selectedDetail)}
                                                    onSelect={(valueObj) => {
                                                        setSelectedDetail((prev) => ({ ...prev, params: valueObj }));
                                                    }}
                                                    selectedDetail={{ ...selectedDetail, params: parsedParams }}
                                                />
                                            </UiDSection>
                                        )}

                                    {selectedDetail.action === "realestate-first-page" && (
                                        <RealEstateFirst selectedScene={selectedScene} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />
                                    )}
                                    {selectedDetail.action === "realestate-last-page" && (
                                        <RealEstateLast selectedScene={selectedScene} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />
                                    )}
                                    {/* ONLY FOR GENERATED VIDEOS */}

                                    {parsedParams.text !== undefined && selectedDetail.action !== "generate-video" && (
                                        <UiDSection title="Text">
                                            <div className="!border !border-lightblue100 rounded-xl p-2 w-full">
                                                <textarea
                                                    value={parsedParams.text}
                                                    onChange={(e) =>
                                                        setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, text: e.target.value } }))
                                                    }
                                                />
                                            </div>
                                        </UiDSection>
                                    )}

                                    {parsedParams.option !== undefined && (
                                        <Option
                                            selectedScene={selectedScene}
                                            selectedDetail={{ ...selectedDetail, params: parsedParams }}
                                            setSelectedDetail={setSelectedDetail}
                                        />
                                    )}
                                    {(parsedParams.duration !== undefined || parsedParams.strength !== undefined) && (
                                        <Range selectedScene={selectedScene} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />
                                    )}

                                    {selectedDetail.action === "overlay-text" && (
                                        <Font
                                            stageSize={stageSize}
                                            selectedScene={selectedScene}
                                            selectedDetail={{ ...selectedDetail, params: parsedParams }}
                                            setSelectedDetail={setSelectedDetail}
                                        />
                                    )}

                                    {parsedParams.x !== undefined &&
                                        parsedParams.y !== undefined &&
                                        selectedDetail.action !== "subtitles" &&
                                        selectedDetail.action !== "realestate-first-page" && (
                                            <UiDSection title="Position">
                                                <Position
                                                    stageSize={stageSize}
                                                    selectedDetail={{ ...selectedDetail, params: parsedParams }}
                                                    setSelectedDetail={setSelectedDetail}
                                                />
                                            </UiDSection>
                                        )}

                                    {parsedParams.width !== undefined &&
                                        parsedParams.height !== undefined &&
                                        selectedDetail.type !== "text" &&
                                        selectedDetail.action !== "subtitles" &&
                                        selectedDetail.action !== "realestate-first-page" && (
                                            <UiDSection title="Fit">
                                                <Fit
                                                    stageSize={stageSize}
                                                    selectedDetail={{ ...selectedDetail, params: parsedParams }}
                                                    setSelectedDetail={setSelectedDetail}
                                                />
                                            </UiDSection>
                                        )}

                                    {parsedParams.volume !== undefined && (
                                        <UiDSection>
                                            <RangeSlider
                                                title="Volume"
                                                initialValue={parsedParams.volume}
                                                min={0}
                                                max={1}
                                                step={0.01}
                                                OnValue={(val) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, volume: val } }))}
                                            />
                                        </UiDSection>
                                    )}

                                    {/* ---------- TYPE  */}

                                    {selectedDetail.type === "audio" && (
                                        <UiDSection className="flex-1 h-full">
                                            <Audio selectedDetail={{ ...selectedDetail, params: parsedParams }} setSelectedDetail={setSelectedDetail} />
                                        </UiDSection>
                                    )}

                                    {/* ---------- ACTION  */}

                                    {selectedDetail.action === "subtitles" && (
                                        <Subtitle
                                            stageSize={stageSize}
                                            selectedScene={selectedScene}
                                            selectedDetail={{ ...selectedDetail, params: parsedParams }}
                                            setSelectedDetail={setSelectedDetail}
                                        />
                                    )}

                                    {selectedDetail.type === "transition" && (
                                        <Transition
                                            project={project}
                                            setProject={setProject}
                                            stageSize={stageSize}
                                            selectedScene={selectedScene}
                                            setSelectedScene={setSelectedScene}
                                            selectedDetail={{ ...selectedDetail, params: parsedParams }}
                                            setSelectedDetail={setSelectedDetail}
                                        />
                                    )}
                                </div>
                                {/* <div
                                    className="sticky bottom-0 rounded-b-2xl bg-white w-full py-5 text-sm text-center cursor-pointer text-blue hover:text-blue/80"
                                    onClick={async () => {
                                        await handleStep({
                                            opr: "update",
                                            params: selectedDetail.params,
                                            project,
                                            selectedDetail,
                                            setSelectedDetail,
                                            stageSize,
                                        });
                                    }}
                                >
                                    Save Step
                                </div> */}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SidebarDetail;
