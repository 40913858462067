import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { MdOutlineEdit } from "react-icons/md";

import { IoIosArrowBack } from "react-icons/io";
import { IoClose } from "react-icons/io5";

import Project from "src/modules/admin/studio/sidebar/project/project";
import UiDSection from "src/modules/admin/studio/components/ui-dsection";
import Input from "src/modules/admin/studio/input/input";
import Step from "src/modules/admin/studio/step/step";
import UiColorPicker from "../components/ui-color-picker";

import Preloader from "src/common/components/preloader/preloader";

import { handleScene } from "../handlers/scene-handler";
import { handleStep } from "../handlers/step-handler";

import VideoPlayer from "src/common/components/video-player/video-player";

const Sidebar = (props) => {
    const navigate = useNavigate();
    const {
        scenes,
        setScenes,
        screens,
        project,
        setProject,
        selectedScene,
        setSelectedScene,
        selectedDetail,
        setSelectedDetail,
        setShowStepAdd,
        stageSize,
        showInputAdd,
        setShowInputAdd,
    } = props;
    const [showActions, setShowActions] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [theSteps, setTheSteps] = useState([]);

    const prevSteps = useRef([]);

    // useEffect(() => {
    //     console.log("selectedScene ::", selectedScene);
    //     if (selectedScene?.id && JSON.stringify(selectedScene.steps) !== JSON.stringify(prevSteps.current)) {
    //         handleScene({
    //             opr: "getSteps",
    //             scene: selectedScene,

    //             project,
    //             stageSize,
    //             setSelectedScene,
    //             setScenes,

    //             setErrorMsg,
    //         });
    //     }
    // }, [selectedScene?.id]);

    const handleDragEnd = async (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            // Get current steps
            const oldIndex = selectedScene.steps.findIndex((step) => step.id === active.id);
            const newIndex = selectedScene.steps.findIndex((step) => step.id === over.id);
            const newSteps = arrayMove(selectedScene.steps, oldIndex, newIndex);

            // Update local state first
            setSelectedScene((prev) => ({ ...prev, steps: newSteps }));

            // Prepare reorder data
            const input_reorder_steps = newSteps.map((step, index) => ({
                id: step.id,
                index: parseInt(index + 1),
            }));

            // Then update backend
            await handleStep({
                opr: "reorder",
                steps: input_reorder_steps,
                selectedScene,
                setSelectedScene,
                selectedDetail,
                setSelectedDetail,
            });
        }
    };

    const canGenerate = () => {
        if (!selectedScene?.steps || selectedScene.generating) return false;

        // Check all steps with generate-video action
        const generateVideoSteps = selectedScene.steps.filter((step) => step?.action === "generate-video");
        for (const step of generateVideoSteps) {
            if (!step.params.name || step.params.name === "" || !step.params.text || step.params.text === "" || !step.params.voiceObj) {
                return false;
            }
        }
        const stepsWithSrc = selectedScene.steps.filter((step) => step?.params?.src);
        for (const step of stepsWithSrc) {
            if (step.params.src === "null" || step.params.src === "" || step.params.src === null || step.params.src === undefined) {
                return false;
            }
        }

        return true;
    };

    return (
        <>
            {errorMsg && (
                <div className="error-msg absolute top-0 left-0 w-full z-[999999] p-2 rounded-2xl">
                    <div className="slideFromBot error-msg p-2 bg-amber-100 text-amber-600 border border-amber-300 z-50 rounded-2xl text-center flex items-center justify-center">
                        <div className="flex-1">{errorMsg}</div>
                        <IoClose className="w-5 h-5  cursor-pointer" onClick={() => setErrorMsg(null)} />
                    </div>
                </div>
            )}
            <div className="sidebar-container relative flex flex-col w-300 h-full gap-4 bg-white border border-lightblue100 rounded-2xl ">
                {selectedScene?.generating && <Preloader logo={false} text={"Generating Scene"} />}
                <div className="absolute top-0 left-0 w-full h-full overflow-y-auto overflow-x-hidden flex flex-col">
                    {!selectedScene && project && <Project screens={screens} project={project} setProject={setProject} />}
                    {selectedScene && (
                        <div className="scene-detail h-full flex-1 flex flex-col relative">
                            <UiDSection>
                                <div className="flex flex-row gap-2 items-center">
                                    <div
                                        className="cursor-pointer opacity-50 hover:opacity-100 -translate-x-2"
                                        onClick={() => {
                                            setSelectedScene(null);
                                            setSelectedDetail(null);
                                        }}
                                    >
                                        <IoIosArrowBack className="text-md" />
                                    </div>
                                    <input
                                        type="text"
                                        className="!p-0 w-full"
                                        placeholder="Add Scene Name"
                                        value={selectedScene.name}
                                        onChange={(e) => setSelectedScene((prev) => ({ ...prev, name: e.target.value }))}
                                    />
                                    <MdOutlineEdit className="text-lg  cursor-pointer opacity-40" />
                                </div>
                            </UiDSection>
                            <div className="relative">
                                {/* {selectedScene.steps?.length > 0 && (
                                    <div className="timeliner absolute top-6 left-1/2 -translate-x-1/2 w-[1px] h-full bg-indigo-800/30 "></div>
                                )}
                                {!(selectedScene.steps[0]?.type === "transition" || selectedScene.steps[0]?.action === "overlay-videos") && (
                                    <Input
                                        showInputAdd={showInputAdd}
                                        setShowInputAdd={setShowInputAdd}
                                        selectedScene={selectedScene}
                                        setSelectedScene={setSelectedScene}
                                        selectedDetail={selectedDetail}
                                        setSelectedDetail={setSelectedDetail}
                                    />
                                )} */}

                                {selectedScene.result !== "" && selectedScene.result.includes("https://") && (
                                    <div className="scene-result p-6 flex flex-col gap-3 border-b border-lightblue100 cursor-pointer" onClick={() => setSelectedScene((prev) => ({ ...prev, mode: "preview" }))} >
                                        <div className="text-sm text-black/40">Result:</div>
                                        <div className="scene-result-video bg-black rounded-2xl w-full h-52 relative overflow-hidden border border-lightblue100">
                                            <VideoPlayer src={selectedScene.result} fluid={true} hoverPlay={true} actions={false} fit="contain" muted={true} />
                                        </div>
                                    </div>
                                )}

                                {selectedScene.steps?.length > 0 && (
                                    <div className="scene-steps-actions flex gap-3 text-sm p-5 pb-0 flex-row items-center justify-between">
                                        <div className="flex flex-row gap-2 items-center">
                                            <div className="text-sm text-black/40">Steps:</div>
                                        </div>
                                        <div
                                            className={` cursor-pointer ${showActions ? "text-blue" : "text-black/30 hover:text-black"}`}
                                            onClick={() => setShowActions(!showActions)}
                                        >
                                            {showActions ? "Done" : "Edit"}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <UiDSection className="flex-1 flex flex-col">
                                {selectedScene.steps && (
                                    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                        <SortableContext items={selectedScene?.steps?.map((step) => step.id)} strategy={verticalListSortingStrategy}>
                                            <div className="scene-steps-container flex-1 flex flex-col gap-3 relative">
                                                {selectedScene?.steps?.length > 0 && (
                                                    <div className="scene-steps flex flex-col gap-3 relative">
                                                        {selectedScene.steps?.length > 1 && (
                                                            <div className="timeliner absolute top-0 left-1/2 -translate-x-1/2 w-[1px] h-full bg-indigo-800/30 "></div>
                                                        )}
                                                        {selectedScene.steps?.map((step) => (
                                                            <Step
                                                                key={step.id}
                                                                step={step}
                                                                selectedScene={selectedScene}
                                                                setSelectedScene={setSelectedScene}
                                                                selectedDetail={selectedDetail}
                                                                setSelectedDetail={setSelectedDetail}
                                                                showActions={showActions}
                                                            />
                                                        ))}
                                                    </div>
                                                )}

                                                {/* {(selectedScene?.input?.params?.src ||
                                                selectedScene.steps?.[0]?.type === "transition" ||
                                                selectedScene.steps?.[0]?.action === "overlay-videos") && ( */}

                                                <div
                                                    className="transition scene-step-add flex items-center gap-2 p-3 group border border-dashed border-indigo-800/30 rounded-xl cursor-pointer hover:border-blue hover:bg-blue/10 text-black/30 hover:text-blue items-center justify-center"
                                                    onClick={() => setShowStepAdd(true)}
                                                >
                                                    <div className="scene-step-action w-6 flex items-center items-center justify-center text-lg">+</div>
                                                    <div className="scene-step-add-type flex-1">Add</div>
                                                </div>
                                            </div>
                                        </SortableContext>
                                    </DndContext>
                                )}
                            </UiDSection>
                            {/* 
                            <UiDSection title="Design" className="!border-b !border-transparent">
                                <UiColorPicker
                                    pickerPos="top"
                                    showDefaultColors={false}
                                    title="Background"
                                    value={selectedScene?.background}
                                    setValue={(val) => setSelectedScene((prev) => ({ ...prev, background: val }))}
                                />
                            </UiDSection> */}

                            {selectedScene.steps?.length > 0 && (
                                <div className="sticky bottom-0 bg-white border-t border-lightblue100 rounded-b-2xl p-5">
                                    <button
                                        disabled={!canGenerate()}
                                        className={`button px-4 py-4 rounded-xl w-full ${selectedScene?.generating ? "loading cursor-not-allowed" : ""}`}
                                        onClick={async () => {
                                            if (canGenerate()) {
                                                try {
                                                    setSelectedScene((prev) => ({ ...prev, generating: true }));

                                                    setErrorMsg(null);
                                                    setSelectedDetail(null);

                                                    const generateScene_res = await handleScene({
                                                        opr: "generate-scene",
                                                        scene: selectedScene,

                                                        project,
                                                        selectedDetail,
                                                        setSelectedDetail,
                                                        stageSize,
                                                        selectedScene,
                                                        setSelectedScene,
                                                        scenes,
                                                        setScenes,
                                                    });
                                                    console.log("generateScene_res :: ", generateScene_res);

                                                  //  if (generateScene_res === 'completed') {

                                                    if (selectedScene.id === generateScene_res.id) {
                                                        setSelectedScene((prev) => {
                                                            if (generateScene_res?.id === prev.id) {
                                                                return {
                                                                    ...prev,
                                                                    result: generateScene_res.result,
                                                                    generating: false,
                                                                    steps: generateScene_res.steps,
                                                                };
                                                            }
                                                            return prev;
                                                        });
                                                        setSelectedDetail(null);
                                                    }
                                                } catch (err) {
                                                    setErrorMsg(err.message);
                                                    setSelectedScene((prev) => ({ ...prev, generating: false }));
                                                    console.log("err :: ", err);
                                                }
                                            }
                                        }}
                                    >
                                        {selectedScene?.generating ? "Generating" : "Generate Scene"}
                                    </button>
                                    <div
                                        className="w-full mt-5 text-sm text-center cursor-pointer text-blue hover:text-blue/80"
                                        onClick={async () => {
                                            await handleScene({
                                                opr: "save",
                                                id: selectedScene.id,
                                                scene: selectedScene,

                                                project,
                                                selectedDetail,
                                                setSelectedDetail,
                                                stageSize,
                                                selectedScene,
                                                setSelectedScene,
                                                scenes,
                                                setScenes,
                                            });
                                        }}
                                    >
                                        Save Scene
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Sidebar;
