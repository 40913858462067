import React, { useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Iicon from "src/common/components/iicon/iicon";
import Logo from "src/common/components/logo/logo";

import { BiUserVoice } from "react-icons/bi";

import { MdOutlineOndemandVideo } from "react-icons/md";
import { FaRegImages } from "react-icons/fa";
import { PiVideoBold } from "react-icons/pi";
import { MdOutlineVideoLibrary } from "react-icons/md";

import { IoGridOutline } from "react-icons/io5";
import { SiMaterialdesignicons } from "react-icons/si";
import { RiFireLine } from "react-icons/ri";
import { AiFillFire } from "react-icons/ai";
import { MdOutlineLocalFireDepartment } from "react-icons/md";
import { AiOutlineFire } from "react-icons/ai";
import { TbUserSquareRounded } from "react-icons/tb";
import { TbUserSquare } from "react-icons/tb";
import { RiHome3Line } from "react-icons/ri";

import { CgPlayButtonR } from "react-icons/cg";

import { CgUser } from "react-icons/cg";





import { TbPhotoVideo } from "react-icons/tb";
import CoinCard from "./coins/coin-card";
import UserCard from "./user/user-card";

const AdminSidebar = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { pathname } = useLocation();
    const [showMenu, setShowMenu] = useState(false);

    const mainmenu = [
        // {
        //     id: "separator",
        // },

        {
            id: "home",
            path: "/admin/home",
            name: "Home",
            icon: <RiHome3Line className=" w-6 h-6" />,
        },

        {
            id: "hot",
            path: "/admin/hot",
            name: "Trending Videos",
            icon: <AiOutlineFire className=" w-6 h-6" />,
        },

        // {
        //     id: "separator",
        // },

        {
            id: "generated-videos",
            path: "/admin/generated-videos",
            name: "Generated Videos",
            icon: <MdOutlineVideoLibrary className=" w-6 h-6" />,
        },


        {
            id: "avatars",
            path: "/admin/avatars",
            name: "Avatars",
            icon: <TbUserSquare className=" w-6 h-6" />,
        },
        // {
        //     id: "input-videos",
        //     path: "/admin/input-videos",
        //     name: "Avatar Videos",
        //     icon: <MdOutlineOndemandVideo className="text-xl" />,
        // },
        // {
        //     id: "input-images",
        //     path: "/admin/input-images",
        //     name: "Avatar Images",
        //     icon: <FaRegImages className="text-xl" />,
        // },
        {
            id: "voices",
            path: "/admin/voices",
            name: "Voices",
            icon: <BiUserVoice className=" w-6 h-6" />,
        },

        // {
        //     id: "separator",
        // },

        // {
        //     id: "campaigns",
        //     path: "/admin/campaigns",
        //     name: "Your Campaigns",
        //     icon: <IoGridOutline className="text-xl" />,
        // },

        // {
        //     id: "separator",
        // },

        // {
        //     id: "campaigns",
        //     path: "/admin/studio",
        //     name: "Studio",
        //     icon: <SiMaterialdesignicons className="text-lg" />,
        // },
        // {
        //     id: "studio-videos",
        //     path: "/admin/studio/videos",
        //     name: "Studio Videos",
        //     icon: <TbPhotoVideo className="text-xl" />,
        // },

        // {
        //     id: "dashboard",
        //     name: "Dashboard",
        //     icon: "home-blue",
        //     path:"/admin/dashboard"
        // },

        // {
        //     id: "separator",
        // },
        // {
        //     id: "campaigns",
        //     name: "Campaigns",
        //     icon: "campaign-blue",
        //     path:"/admin"
        // },

        // {
        //     id: "integrations",
        //     name: "Integrations",
        //     icon: "puzzle-blue",
        //     path:"/admin/integrations"
        // },

        // {
        //     id: "separator",
        // },
        // {
        //     id: "campaigns",
        //     name: "Campaigns",
        //     icon: "campaign-blue",
        //     path:"/admin/campaigns"
        // },

        // {
        //     id: "templates",
        //     name: "Templates",
        //     icon: "script-blue",
        //     path:"/admin/templates"
        // },
        // {
        //     id: "lists",
        //     name: "Lists",
        //     icon: "csv-blue",
        //     path:"/admin/lists"
        // },
        // {
        //     id: "integrations",
        //     name: "Integrations",
        //     icon: "puzzle-blue",
        //     path:"/admin/integrations"
        // },

        // {
        //     id: "separator",
        // },
        // {
        //     id: "characters",
        //     name: "Characters",
        //     icon: "character-blue",
        //     path: "/admin/characters",
        // },
        // {
        //     id: "videos",
        //     name: "Videos",
        //     icon: "video-blue",
        //     path: "/admin/videos",
        // },
    ];

    const TheLogo = () => {
        return (
            <div className="flex items-center gap-3">
                <Logo /> <div className="text-xs bg-lightblue100/50 rounded-full p-2 py-1  font-bold">BETA</div>
            </div>
        );
    };

    return (
        <>
            {showMenu && (
                <div
                    className="fixed w-full h-full bg-slate-800 opacity-80 z-50"
                    onClick={() => {
                        setShowMenu(false);
                    }}
                ></div>
            )}
            <div className="md:hidden flex bg-white border-b border-b-lightblue100 px-4 py-6 items-center gap-3 sticky justify-between ">
                <div className="flex items-center gap-3">
                    <div
                        onClick={() => {
                            setShowMenu(true);
                        }}
                    >
                        <Iicon icon={"menu"} className={`cursor-pointer`} />
                    </div>
                    <TheLogo />
                </div>
                <div className="block md:hidden">
                    <UserCard />
                </div>
            </div>
            <div className={`admin-sidebar transition-all fixed md:relative w-96 h-full top-0 md:flex  md:left-0  ${showMenu ? "left-0 z-50 " : "-left-96"}`}>
                <div
                    className={` transition-all  fixed w-96 h-full top-0 md:left-0 bg-white border-r border-lightblue100 flex flex-col  ${
                        showMenu ? "left-0 " : "-left-96"
                    }`}
                >
                    <div className="px-8 py-10 flex justify-center items-center mb-20  gap-3">
                        <TheLogo />
                    </div>

                    {/* <div className="flex flex-col gap-2  mb-8 px-8">
                        <button
                            className="button flex-1 flex items-center gap-5 justify-between"
                            onClick={() => {
                                // navigate(`${location.pathname}`, { state: { show: "create-video" } });
                                navigate(`/admin/generate-video`, { state: { todo: "create" } });
                            }}
                        >
                            <div>Generate Video</div>
                            <PiVideoBold className="text-xl" />
                        </button>
                        <div className="flex items-center gap-1 justify-center text-xs">
                            <CoinCard coins={'5'} /> per generate
                        </div>
                    </div> */}

                    <div className="menu flex flex-col gap-10 flex-1 ">
                        {mainmenu.map((item, i) => (
                            <React.Fragment key={i}>
                                {item.id !== "separator" ? (
                                    <a
                                        href={`/admin/${item.id}`}
                                        className={`transition-all menu-item px-8 py-1 flex gap-6 items-center text-black  hover:opacity-100 ${
                                            item.path === pathname
                                                ? "opacity-100 border-r-2 border-r-blue text-black"
                                                : "hover:text-black hover:translate-x-1 opacity-50"
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(`${item.path}`);
                                        }}
                                    >
                                        <div className="min-w-6 min-h-6 flex items-center justify-center text-blue">{item.icon}</div>
                                        {/* <Iicon icon={item.icon} /> */}
                                        <div className="text">{item.name}</div>
                                    </a>
                                ) : (
                                    <div className="h-px bg-indigo-400/20"></div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>

                    {/* <div className="flex gap-4 items-center p-8 ">
                        <UserCard />
                        <div className="w-12 h-12 rounded-2xl bg-lightblue100"></div>
                    <div className="flex-1">James</div> 
                         <Iicon icon={"more"} />
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default AdminSidebar;
