import { updateStep, deleteStep, reorderStep } from "src/common/services/step";
import { upload } from "src/common/services/utils";
import { getRealVal, assColorToHex, hexToAssColor } from "./utils";

export const handleStep = async ({ steps, opr, step, params, project, selectedDetail, setSelectedDetail, selectedScene, setSelectedScene, stageSize }) => {
    switch (opr) {
        case "select":
            console.log("select step :: ", step);
            setSelectedScene((prev) => ({ ...prev, mode: "edit" }));
            setSelectedDetail(selectedDetail?.id === step.id ? null : { ...step });
            break;
        case "toggleActive":
            setSelectedScene({
                ...selectedScene,
                steps: selectedScene.steps.map((s) => (s.id === step.id ? { ...s, active: !step.active } : s)),
            });
            break;
        case "update":
            // Convert all color values to &H format
            const processedParams = Object.entries(params).reduce((acc, [key, value]) => {
                acc[key] = typeof value === "string" && /^#[0-9A-F]{6}$/i.test(value) ? hexToAssColor(value) : value;
                return acc;
            }, {});

            // const processedParams = Object.entries(params).reduce((acc, [key, value]) => {
            //     // Check if value is a string and contains '#'
            //     acc[key] = typeof value === "string" && value.includes("#") ? 
            //         value.replace("#", "&H") : value;
            //     return acc;
            // }, {});

            //add action if not present
            if (Object.keys(processedParams).length === 0) {
                processedParams.action = selectedDetail.action;
            }

            if (selectedDetail.params.file) {
                try {
                    const upload_res = await upload({ file: selectedDetail.params.file });
                    processedParams.src = upload_res[0].cache;
                } catch (error) {
                    console.error("Failed to process image file:", error);
                }
            }

            const step_real_val = getRealVal({
                stageSize,
                project,
                scaledX: selectedDetail.params?.x,
                scaledY: selectedDetail.params?.y,
                scaledWidth: selectedDetail.params?.width,
                scaledHeight: selectedDetail.params?.height,
            });


            if (selectedDetail.action === "overlay-text" && selectedDetail.type === "base64") {
                const layerToImage = selectedDetail.text_ref.toDataURL({
                    width: processedParams.width, // Optional: desired width
                    height: processedParams.height, // Optional: desired height
                    pixelRatio: 2, // Optional: for higher quality rendering
                });
                processedParams.src = layerToImage;
                delete processedParams.text_ref;
            }


            // Map dimension parameters
            const dimensionParams = ["x", "y", "width", "height"];
            dimensionParams.forEach((param) => {
                if (processedParams[param]) {
                    processedParams[param] = step_real_val[param];
                }
            });

            if (selectedDetail.action === "realestate-first-page") {
                processedParams.price = processedParams.price.replace(/,/g, "");
            }

            if (selectedDetail.action === "realestate-last-page") {
                delete processedParams.x;
                delete processedParams.y;
                delete processedParams.width;
                delete processedParams.height;
                // processedParams.logo = "";
            }

            if (selectedDetail.action === "subtitles") {
                processedParams.highlight = "";
            }

           
            
            
            const update_res = await updateStep(selectedDetail.id, processedParams);
            console.log("step update_res :: ", update_res);

            break;

        case "delete":
            const delete_res = await deleteStep({ id: step.id });
            console.log("delete_res :: ", delete_res);

            setSelectedScene({ ...selectedScene, steps: selectedScene.steps.filter((s) => s.id !== step.id) });
            if (selectedDetail?.id === step.id) {
                setSelectedDetail(null);
            }

            break;

        case "reorder":
            const reorder_res = await reorderStep(steps);
            console.log("reorder_res :: ", reorder_res);

            // setSelectedScene({ ...selectedScene, steps: selectedScene.steps.filter((s) => s.id !== step.id) });

            // if (selectedDetail?.id === step.id || selectedScene.steps.length <= 0) {
            //     setSelectedDetail(null);
            // }

            break;
    }
};
