import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import OnBottom from "src/common/components/on-bottom/on-bottom";
import { getVoices, getVoicesDefault } from "src/common/services/voices";
import CardVoice from "src/modules/admin/voices/card-voice";
import Preloader from "src/common/components/preloader/preloader";
import { MdOutlineVoiceOverOff } from "react-icons/md";
import CardAudio from "./card-audio";
import defaultPic from "src/common/assets/images/default_pic_small.png";
import { getAvatarVoices } from "src/common/services/avatars";
const ListVoices = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [voices, setVoices] = useState([]);

    const [voicesDef, setVoicesDef] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [fetching, setFetching] = useState(null);
    const [onUse, setOnUse] = useState(null);

    useEffect(() => {
        handleVoices({ opr: "list", pageNum: 1 });
    }, [location]);

    useEffect(() => {
        if (props.OnUse && onUse) {
            props.OnUse({ ...onUse });
        }
    }, [onUse]);

    const handleVoices = async ({ opr, pageNum }) => {
        setFetching(true);

        try {
            switch (opr) {
                case "list":
                    const list_res = await getAvatarVoices();
                    //console.log("list_res :: ", list_res);
                    setVoices(list_res);
                    // const list_def_res = await getVoicesDefault();
                    // //console.log("list_def_res :: ", list_def_res);

                   

                    break;
                default:
                    break;
            }
        } catch (error) {
            //console.log(error);
        }

        setFetching(false);
    };
    return (
        <div className=" flex-1 w-full relative slideFromTop">
            {fetching && pageNum === 1 && <Preloader />}

            {/* {!fetching && <div className=" opacity-40 py-6">Default Voices</div>}
            {voicesDef.length > 0 && (
                 <div className={`grid grid-cols-1 gap-6 justify-between ${props.cols ? `grid-cols-${props.cols}` : `grid-cols-4`}`}>
                    {voicesDef.map((voice, v) => (
                        <CardAudio
                            {...props}
                            type={`default`}
                            key={v}
                            image={defaultPic}
                            voice={voice}
                            OnUse={(voice) => {
                                setOnUse(voice);
                            }}
                        />
                    ))}
                </div>
            )} */}

            {/* {!fetching && voices.length > 0 && <div className="mt-4 opacity-40  py-6">Your Voices</div>} */}
            <OnBottom
                OnBottom={async (val) => {
                    if (pageNum > 1) {
                        await handleVoices({ opr: "list", pageNum });
                    }
                }}
            >
                {voices.length > 0 && (
                 <div className={`grid grid-cols-1 gap-6 justify-between ${props.cols ? `grid-cols-${props.cols}` : `grid-cols-4`}`}>
                        {voices.map((voice, v) => (
                            <CardAudio
                                {...props}
                                key={v}
                                voice={voice}
                                OnUse={(voice) => {
                                    setOnUse(voice);
                                }}
                            />
                        ))}
                    </div>
                )}
            </OnBottom>
            {voices.length <= 0 && fetching !== null && (
                <div className="flex h-full flex-1 items-center justify-center">
                    <div className="flex flex-col gap-4 items-center">
                        {/* You have not added any videos yet. */}
                        {/* <button className="button button-small px-4">
                         Upload
                         <input
                             title=""
                             type="file"
                             accept={`image/*`}
                             className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                             onChange={async (e) => {
                                 //console.log(e.target.files[0]);
                                 handleVideos({ opr: "upload", file: e.target.files[0] });
                             }}
                         />
                     </button> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListVoices;
