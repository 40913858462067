import { generateScene, getScene, updateScene, deleteScene, listScenes, createScene } from "src/common/services/scene";
import { listSteps, addStep, updateStep } from "src/common/services/step";
import { v4 as uuidv4 } from "uuid";
import { positions } from "../common";
import { getScaledVal, getRealVal, pollForCompletion } from "./utils";
import { handleStep } from "./step-handler";
import { deleteStep } from "src/common/services/step";
import SceneClass from "../scene/class-scene";
import StepClass from "../step/class-step";
import { assColorToHex, hexToAssColor } from "./utils";

const handleSceneGeneration = async ({ scene, setScenes, project, stageSize }) => {
    const MAX_ATTEMPTS = 60; // 5 minutes with 5-second intervals
    let attempts = 0;

    return await pollForCompletion({
        checkFn: async () => {
            attempts++;
            const pollRes = await getScene({ id: scene.id });
            console.log("poll scene generation ::", pollRes);

            if (pollRes[0]?.result?.includes("https") && pollRes[0].state === "complete") {
                return { success: true, data: pollRes[0] };
            } else if (pollRes[0]?.state?.includes("error") || pollRes[0].state === "error") {
                return {
                    error: `Generation failed ${pollRes[0].state?.substring(0, 180)}${pollRes[0].state?.length > 180 ? "..." : ""}`,
                    stopPolling: true,
                };
            } else if (attempts >= MAX_ATTEMPTS) {
                return {
                    error: "Generation timed out after 5 minutes",
                    stopPolling: true,
                };
            }
            return { success: false };
        },
        onSuccess: async (pollRes) => {
            // Delete all existing steps
            //  const steps_res = await listSteps({ sceneid: scene.id });
            const steps_res = await handleScene({ opr: "getSteps", scene: scene, project, stageSize });
            //  await Promise.all(steps_res.map((step) => deleteStep({ id: step.id })));

            // const generatedVideoStep = await createGeneratedVideoStep({ scene, result: pollRes.result });

            // // after creating the generated video step, delete the scene result and state
            // const deleteSceneResult = await updateScene(scene.id, {

            //     name: scene.name,

            //     result: "",
            //     state: "",
            // });
            // console.log("deleteSceneResult :: ", deleteSceneResult);

            // setScenes((prev) =>
            //     prev.map((s) => (s.id === scene.id ? { ...s, result: pollRes.result, generating: false, steps: [...s.steps, generatedVideoStep] } : s))
            // );

            //return generatedVideoStep;
            const newScene = { ...scene, result: pollRes.result, generating: false, steps: steps_res };
            setScenes((prev) => prev.map((s) => (s.id === scene.id ? newScene : s)));
            return newScene;
        },
        onError: async (error) => {
            setScenes((prev) => prev.map((s) => (s.id === scene.id ? { ...s, generating: false } : s)));
            return scene;
        },
    });
};

const createGeneratedVideoStep = async ({ scene, project, result }) => {
    const theparams = {
        parentid: scene.id,
        label: "Generated Video",
        action: "overlay-video",
        type: "video",
        src: result,
        x: "0",
        y: "0",
        width: project.width,
        height: project.height,
    };

    const resultstep_res = await addStep({
        index: "1",
        ...theparams,
    });

    const generatedVideoStep = StepClass.init({
        name: "Generated Video",
        label: "Generated Video",
        type: "video",
        action: "overlay-video",
        params: theparams,
        parentid: scene.id,
        result: result,
    });

    generatedVideoStep.id = resultstep_res[0].id;
    return generatedVideoStep;
};

export const handleScene = async ({
    opr,
    id,

    input,
    stageSize,

    project,
    setProject,

    scene,
    scenes,
    setScenes,
    selectedScene,
    setSelectedScene,

    selectedDetail,
    setSelectedDetail,
    setErrorMsg,

    previewOnly,

    callback,
}) => {
    switch (opr) {
        case "select":
            if (!previewOnly) {
               
                console.log("select scene::", scene);
                setSelectedDetail(null);
                setSelectedScene((prev) => (prev?.id === scene.id ? null : scene));
            }
            break;

        case "get":
            const get_res = await getScene({ id });
            console.log("get_res :: ", get_res);
            setSelectedScene(get_res);

            break;

        case "add":
            setSelectedDetail(null);
            const newScene = SceneClass.create({
                index: (scenes.length + 1).toString(),
                name: `Scene ${scenes.length + 1}`,
                projectid: project.id,
            });

            console.log("newScene :: ", newScene);
            // Update local state immediately for responsive UX
            setScenes((prev) => [...prev, newScene]);
            setSelectedScene(newScene);

            // Then update with database response
            const input_scene = { ...newScene };
            input_scene.active = "false";
            delete input_scene.steps;
            const create_res = await createScene(input_scene);
            console.log("create_res :: ", create_res);
            // Update the local state with the database ID
            if (create_res[0]?.id) {
                setScenes((prev) => prev.map((scene) => (scene.tempId === newScene.tempId ? { ...scene, id: create_res[0].id } : scene)));

                // Update selected scene if it's the one we just created
                // setSelectedScene((prev) => (prev?.tempId === newScene.tempId ? { ...prev, id: create_res[0].id } : prev));
            }

            break;

        case "list":
            const list_res = await listScenes({ projectid: project.id });
          //  console.log("scenes list_res :: ", list_res);

            // Create a unique set of scenes, preferring database versions
            const mergedScenes = [
                ...list_res.filter(dbScene => !scenes.some(scene => scene.id === dbScene.id)),
                ...scenes
            ];


            // Parse input field as JSON and active as boolean for each scene
            const parsedScenes = mergedScenes.map((scene) => ({
                ...scene,
                mode: "edit",
                active: scene.active === "true" || scene.active === true,
             //   input: scene.input ? JSON.parse(scene.input) : {},
                input: {},
                generating: scene.state === "generating",
            }));

            // First, get all steps for all scenes
            const scenesWithSteps = await Promise.all(
                parsedScenes.map(async (scene) => {
                 
                    if (!scene.steps) {
                        const steps_res = await handleScene({ opr: "getSteps", scene: scene, project, stageSize });
                        return { ...scene, steps: steps_res };
                    }
                    return scene;
                })
            );

            // Then update state synchronously
            setScenes((prevScenes) => {
                const updatedScenes = prevScenes.map((existingScene) => {
                    const updatedScene = scenesWithSteps.find((p) => p.id === existingScene.id);
                    return updatedScene ? { ...existingScene, ...updatedScene } : existingScene;
                });

                // Add any new scenes
                const newScenes = scenesWithSteps.filter((p) => !prevScenes.some((existing) => existing.id === p.id));
                return [...updatedScenes, ...newScenes];
            });

            break;

        case "save":
            const update_res = await updateScene(id, {
                index: scene.index,
                name: scene.name,
                background: scene.background,
            });
            console.log("update scene res :: ", update_res);

            // Create an array of promises for all step updates
            const stepUpdatePromises = scene.steps.map((step) => {
                const theParams = { ...step.params };
                if (step.action === "realestate-first-page") {
                    delete theParams.x;
                    delete theParams.y;
                    delete theParams.width;
                    delete theParams.height;
                }
                if (step.action === "generate-video") {
                    delete theParams.voiceObj;
                    delete theParams.src;
                }

                // Delete any params with "null" value and round float values
                Object.keys(theParams).forEach((key) => {
                    if (theParams[key] === "null") {
                        delete theParams[key];
                    }
                });

                return handleStep({
                    opr: "update",
                    params: theParams,
                    project,
                    selectedScene,
                    selectedDetail: step,
                    setSelectedDetail,
                    stageSize,
                });
            });

            // Execute all step updates in parallel
            await Promise.all(stepUpdatePromises);
            break;

        case "active":
            setScenes((prev) => prev.map((s) => (s.id === scene.id ? { ...s, active: !s.active } : s)));
            break;

        case "delete":
            // Update local state immediately for responsive UX
            setScenes((prev) => prev.filter((s) => s.id !== scene.id));
            if (selectedScene?.id === scene.id) {
                setSelectedScene(null);
                if (selectedDetail?.parentid === scene.id) {
                    setSelectedDetail(null);
                }
            }

            // Then handle server deletion
            const delete_res = await deleteScene({ id: scene.id });
            console.log("delete_res :: ", delete_res);

            // If deletion fails, we could potentially restore the scene
            if (!delete_res) {
                console.error("Failed to delete scene");
                // Restore the scene in local state
                setScenes((prev) => [...prev, scene]);
            }
            break;

        case "getSteps":
            const steps_res = await listSteps({ sceneid: scene.id });
            // console.log(`${scene.name} : steps_res ::`, steps_res);

            // if (scene.state === "generating" || scene.state === "complete") {
            //     return await handleSceneGeneration({ scene, setScenes });
            // }

            //if (scene.state === "complete") {
            // Check if a "Generated Video" step already exists

            // const hasGeneratedVideoStep = steps_res.some((step) => step.label === "Generated Video");

            // if (!hasGeneratedVideoStep) {
            //     const generatedVideoStep = await createGeneratedVideoStep({ scene, result: scene.result });
            //     console.log("generatedVideoStep :: ", generatedVideoStep);
            //     parsedSteps.push(generatedVideoStep);
            // }
            //}

            //STEPS

            const parsedSteps = steps_res.map((step) => {
                const params = typeof step.params === "string" ? JSON.parse(step.params) : step.params;
                // Parse any color values in the params
                const parsedParams = { ...params };

                Object.entries(params || {}).forEach(([key, value]) => {
                    if (typeof value === "string" && value.startsWith("&H")) {
                        parsedParams[key] = assColorToHex(value);
                    }
                });

                const step_scaled_val = getScaledVal({
                    stageSize,
                    project,
                    originalX: parsedParams.x,
                    originalY: parsedParams.y,
                    originalWidth: parsedParams.width,
                    originalHeight: parsedParams.height,
                });

                const shouldRecalculate = (value) => {
                    return value === "null" || value === undefined;
                };

                // Map dimension parameters
                const dimensionParams = ["x", "y", "width", "height"];
                dimensionParams.forEach((param) => {
                    if (parsedParams[param]) {
                        parsedParams[param] = shouldRecalculate(parsedParams[param]) ? "0" : step_scaled_val[param];
                    }
                });

                return {
                    ...step,
                    params: parsedParams,
                };
            });

            // console.log("parsedSteps :: ", parsedSteps);
           // console.log(`${scene.name} > steps > `, parsedSteps);

            return parsedSteps;

            

            // Only update if the steps are different
            // if (JSON.stringify(scene.steps) !== JSON.stringify(parsedSteps)) {
            //     //setTheSteps(parsedSteps);
            //     // setSelectedScene({ ...scene, steps: parsedSteps });
            // }

            // if (setScenes) {
            //     setScenes((prev) => prev.map((s) => (s.id === scene.id ? { ...s, generating: false, steps: parsedSteps } : s)));
            // }

            // callback && callback(parsedSteps);

            break;

        case "generate-scene":
            setScenes((prev) => prev.map((s) => (s.id === scene.id ? { ...s, generating: true } : s)));

            await handleScene({ opr: "save", id: scene.id, scene, project, selectedDetail, setSelectedDetail, stageSize });
            console.log("saved scene :: ", scene.id);

            // if (scene.result.includes("https")) {
            //     const generatedVideoStep = await createGeneratedVideoStep({ scene, project, result: scene.result });
            //     console.log("generatedVideoStep :: ", generatedVideoStep);
            // }

            console.log("generating scene :: ", scene.id);
            const generate_res = await generateScene(project.id, scene.id);
            console.log("generate_res :: ", generate_res);

            if (generate_res[0]?.result) {
                return await handleSceneGeneration({ scene, setScenes, project, stageSize });
            }
            break;

        default:
            break;
    }
};
