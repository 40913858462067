import { useState, useEffect } from "react";
import axios from "axios";
import StepClass from "src/modules/admin/studio/step/class-step";
import SceneClass from "src/modules/admin/studio/scene/class-scene";

import { createScene } from "src/common/services/scene";
import { addStep } from "src/common/services/step";
import { handleScene } from "src/modules/admin/studio/handlers/scene-handler";
import { TfiLayout } from "react-icons/tfi";
import { TfiLayersAlt } from "react-icons/tfi";

import Preloader from "src/common/components/preloader/preloader";
import { scenesTemplates, sequencesTemplates } from "src/modules/admin/studio/scene/scene-templates";

const SceneAdd = (props) => {
    const { project, scenes, setScenes, selectedDetail, stageSize, setSelectedDetail, selectedScene, setSelectedScene, setShowSceneAdd, setFetching } = props;
    const [search, setSearch] = useState("");

    const tabs = ["scenes", "templates"];
    const [selectedTab, setSelectedTab] = useState("scenes");

    const [sequences, setSequences] = useState([]);

    const templates = scenesTemplates(project);
    console.log("templates :: ", templates);
    useEffect(() => {
        if (selectedTab === "templates") {
            // const fetchData = async () => {
            //     try {
            //         const data = await axios.post(
            //             "http://localhost:5001/api/scrape-redfin",
            //             {
            //                 url: "https://www.redfin.com/CA/Huntington-Beach/1200-Pacific-Coast-Hwy-92648/unit-325/home/5714134",
            //             },
            //             {
            //                 headers: {
            //                     "Content-Type": "application/json",
            //                     Accept: "application/json",
            //                 },
            //             }
            //         );

            //         const thsequences = sequencesTemplates(project, data);
            //         setSequences(thsequences);
            //     } catch (error) {
            //         console.log("error :: ", error);
            //     }
            // };
            // fetchData();

            const thsequences = sequencesTemplates(project, null);
            setSequences(thsequences);
        }
    }, [selectedTab]);

    const sceneCreator = async (selScene) => {
        const newScene = SceneClass.create({
            index: (scenes.length + 1).toString(),
            name: `${selScene.name} ${scenes.length + 1}`,
            projectid: project.id,
        });

        const input_scene = { ...newScene };
        input_scene.active = "false";
        delete input_scene.steps;

        const newScene_res = await createScene(input_scene);
        newScene.id = newScene_res[0].id;

        //CREATE THE STEP

        console.log("selScene :: ", selScene);
        for (let index = 0; index < selScene.steps.length; index++) {
            const newStep = selScene.steps[index];

            const input_step = {
                ...newStep.params,
                index: (index + 1).toString(),
                parentid: newScene_res[0].id,
                type: newStep.type,
                action: newStep.action,
                label: newStep.params.label,
            };

            console.log("input_step :: ", input_step);
            const add_step_res = await addStep(input_step);

            console.log("add_step_res :: ", add_step_res);

            //RECREATE THE STEP OBJECT to use for localupdate
            newStep.id = add_step_res[0].id;
            newScene.addStep(newStep);
        }

        return newScene;
    };

    const runScene = async (selScene) => {
        setFetching(true);
        try {
            const newScene = await sceneCreator(selScene);

            // setScenes((prev) => [...prev, newScene]);
            

            await handleScene({
                opr: "list",
                project,
                scenes,
                setScenes,
                selectedScene,
                setSelectedScene,
                selectedDetail,
                setSelectedDetail,
                stageSize,
                // callback: (res) => {
                //     const findNewScene = res.find((s) => s.id === newScene.id);
                //     if (findNewScene) {
                //         // findNewScene.steps = newScene.steps;
                //         console.log("findNewScene :: ", findNewScene);
                //         setSelectedScene(findNewScene);
                //     }
                // },
            });
            setSelectedDetail(null);
            setShowSceneAdd(false);
            setFetching(false);
        } catch (error) {
            console.log("error :: ", error);
            setFetching(false);
        }
    };

    const runSequence = async (selSequence) => {
        setFetching(true);
        const reversedSequence = selSequence.sequence.reverse();
        try {
            for (let index = 0; index < reversedSequence.length; index++) {
                const scene = reversedSequence[index];

                const newScene = await sceneCreator(scene);
            }

            await handleScene({
                opr: "list",
                project,
                scenes,
                setScenes,
                selectedScene,
                setSelectedScene,
                selectedDetail,
                setSelectedDetail,
                callback: (scenes) => {
                    const findNewScene = scenes.find((s) => s.id === reversedSequence[0].id);
                    if (findNewScene) {
                        // findNewScene.steps = newScene.steps;
                        console.log("findNewScene :: ", findNewScene);
                        setSelectedScene(findNewScene);
                    }
                },
            });

            setSelectedDetail(null);
            setShowSceneAdd(false);
            setFetching(false);
        } catch (error) {
            console.log("error :: ", error);
            setFetching(false);
        }
    };

    return (
        <div className="flex flex-col min-h-800 max-h-800 relative overflow-hidden">
            <div className="flex w-full flex-col  gap-8 items-start justify-between p-10">
                <div className=" text-lg">Add Scene</div>
                <div className="flex flex-1 w-full flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2 ">
                    {tabs.map((tab, t) => (
                        <div
                            key={t}
                            className={`capitalize transition flex-1 cursor-pointer ${
                                tab === selectedTab ? "opacity-100 bg-white rounded-xl py-2" : "opacity-50"
                            }`}
                            onClick={() => {
                                setSelectedTab(tab);
                            }}
                        >
                            <div className="">{tab}</div>
                        </div>
                    ))}
                </div>
                {/* <div className="w-full step-library-search border border-lightblue100 rounded-2xl p-2">
                    <input type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                </div> */}
            </div>
            {selectedTab === "scenes" && (
                <div className="grid grid-cols-3 gap-10 p-10 pt-0">
                    {templates.map((scene, a) => (
                        <div
                            key={a}
                            className={`flex flex-col gap-4 cursor-pointer ${
                                typeof scene.is_enabled === "function" && scene.is_enabled() === false ? "opacity-50 pointer-events-none" : ""
                            }`}
                            onClick={async () => await runScene(scene)}
                        >
                            <div className="w-full h-52 bg-lightblue100/50 rounded-xl flex items-center justify-center">
                                <TfiLayout className="w-16 h-16 text-blue/50" />
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="font-semibold">{scene.name}</div>
                                <div className="text-sm">{scene.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {selectedTab === "templates" && (
                <div className="grid grid-cols-3 gap-10 p-10 pt-0">
                    {sequences.map((sequence, a) => (
                        <div
                            key={a}
                            className={`flex flex-col gap-4 cursor-pointer ${
                                typeof sequence.is_enabled === "function" && sequence.is_enabled() === false ? "opacity-50 pointer-events-none" : ""
                            }`}
                            onClick={async () => await runSequence(sequence)}
                        >
                            <div className="w-full h-52 bg-lightblue100/50 rounded-xl flex items-center justify-center">
                                <TfiLayersAlt className="w-16 h-16 text-blue/50" />
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="font-semibold">{sequence.name}</div>
                                <div className="text-sm">{sequence.description}</div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SceneAdd;
