import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import endpoint from "src/common/services/endpoints";
import gsap from "gsap";

import pinesLeft from "./images/pines-left.svg";
import pinesRight from "./images/pines-right.svg";
import dots from "./images/dots.png";
import mountain from "./images/mountain.svg";
import mountain2 from "./images/mountain-2.svg";
import snowflake from "./images/snowflake.svg";
import snowflakeWhite from "./images/snowflake-white.svg";
import santa from "./images/santa.png";
import santaThumb from "./images/santa-thumb.png";
import gifts1 from "./images/gifts-1.png";
import gifts2 from "./images/gifts-2.png";

import { startSnowfall } from "./scripts/snowflakes";
import "./styles/snowflakes.css";
import GenerateMessage from "./components/generate-message";

import heroText from "./images/hero-text.svg";
const Santa = () => {
    const [searchParams] = useSearchParams();
    const [isStep, setIsStep] = useState(1);
    const [messageObj, setMessageObj] = useState({
        from: "",
        to: "",
        text: "",
    });
    const [errorMsg, setErrorMsg] = useState(null);
    const [sending, setSending] = useState(false);
    const [thevideo, setThevideo] = useState(null);
    const [showGenerateMessage, setShowGenerateMessage] = useState(false);

    const heroTextRef = useRef(null);
    const pinesLeftRef = useRef(null);
    const pinesRightRef = useRef(null);
    const mountainRef = useRef(null);
    const mountain2Ref = useRef(null);
    const gifts1Ref = useRef(null);
    const gifts2Ref = useRef(null);

    useEffect(() => {
        startSnowfall();

        // Add pine trees swaying animation
        gsap.to([pinesLeftRef.current, pinesRightRef.current], {
            rotation: 2,
            skewX: 1.05,
            duration: 2,
            ease: "sine.inOut",
            yoyo: true,
            repeat: -1,
            transformOrigin: "bottom",
        });

        // Mouse move animation
        const handleMouseMove = (e) => {
            const { clientX, clientY } = e;
            const xPos = (clientX / window.innerWidth - 0.5) * 20;
            const yPos = (clientY / window.innerHeight - 0.5) * 20;

            // Existing heroText animation
            gsap.to(heroTextRef.current, {
                x: xPos,
                y: yPos,
                rotateX: -yPos * 0.2,
                rotateY: xPos * 0.2,
                duration: 1,
                ease: "power2.out",
            });

            // Mountain animation
            gsap.to(mountainRef.current, {
                x: xPos * 2.2,
                y: yPos * 2.8,
                duration: 1.5,
                ease: "power2.out",
            });

            // Mountain2 animation (opposite direction)
            gsap.to(mountain2Ref.current, {
                x: -xPos * 2.2,
                y: -yPos * 2.8,
                duration: 1.5,
                ease: "power2.out",
            });

            // New pine trees mouse movement
            gsap.to(pinesLeftRef.current, {
                x: xPos * 1.8,
                y: yPos * 0.4,
                duration: 2,
                ease: "power2.out",
            });

            gsap.to(pinesRightRef.current, {
                x: xPos * 1.8,
                y: yPos * 0.4,
                duration: 2,
                ease: "power2.out",
            });

            // Add gift animations
            gsap.to(gifts1Ref.current, {
                x: xPos * 1.8,
                y: yPos * 0.3,
                duration: 2,
                ease: "power2.out",
            });

            gsap.to(gifts2Ref.current, {
                x: xPos * 1.2,
                y: yPos * 0.3,
                duration: 2,
                ease: "power2.out",
            });
        };

        window.addEventListener("mousemove", handleMouseMove);

        // Cleanup
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    useEffect(() => {
        // Get specific query parameters
        const ref = searchParams.get("ref");
    }, [searchParams]);

    const handleSubmit = async (a) => {
        if (!messageObj.to || !messageObj.from || !messageObj.text) {
            return;
        }
        const ref = searchParams.get("ref");
        try {
            setSending(true);
            const create_res = await axios.post(`${endpoint.rest}santa/create${ref ? `?ref=${ref}` : ""}`, messageObj, {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            console.log("create_res ::", create_res);

            if (create_res.data.result[0].result) {
                const id = create_res.data.result[0].result;
                setIsStep(3);
            } else {
                setErrorMsg("Message was not sent. Try again later");
            }
        } catch (error) {
            console.log(error);
            setErrorMsg("An error occurred. Please try again.");
        } finally {
            setSending(false);
        }
    };
    return (
        <div
            className="relative overflow-hidden bg-white h-full w-full"
            // style={{ backgroundImage: `url(${dots})` }}
        >
            <div className="hero-text flex flex-col items-center justify-center h-1/2 leading-[120px] relative">
                <img ref={heroTextRef} src={heroText} alt="hero-text" className="w-[47%] mt-20" style={{ transform: "perspective(1000px)" }} />

                {/* <div className="text-[160px] grand-hotel text-center bg-white relative z-20 text-red-700">Christmas</div>
                <div className="text-[300px] grand-hotel text-center relative z-10 bg-gradient-to-t from-red-700 to-red-500 text-transparent bg-clip-text leading-[380px] py-4">Greetings</div> */}
            </div>

            <img src={snowflake} alt="snowflake" className="absolute left-[200px] top-[120px] w-[250px] opacity-20 spinning-slow" />

            <img src={snowflake} alt="snowflake" className="absolute right-[200px] top-[20px] w-[160px] opacity-20 spinning-slow" />

            <img src={snowflake} alt="snowflake" className="absolute -right-[100px] bottom-[200px] w-[360px] opacity-10 spinning-slow" />

            <img src={snowflake} alt="snowflake" className="absolute -left-[100px] bottom-[300px] w-[200px] opacity-20 spinning-slow" />

            <div className="absolute left-0 -bottom-[0px] w-[100%] z-30">
                <img ref={mountain2Ref} src={mountain2} alt="mountain" className="absolute left-0 bottom-[60px] w-[150%] opacity-30" />
                <img ref={mountainRef} src={mountain} alt="mountain" className="absolute left-0 bottom-[10px] w-[100%]" />
            </div>

            <img ref={gifts1Ref} src={gifts1} alt="gifts-1" className="absolute -left-20 -bottom-[20px] w-[800px] z-50" />

            <img ref={gifts2Ref} src={gifts2} alt="gifts-2" className="absolute right-0 -bottom-[20px] w-[800px] z-50" />

            <img ref={pinesLeftRef} src={pinesLeft} alt="pines-left" className="absolute -left-10 -bottom-[10px] w-[500px] z-40 " />
            <img ref={pinesRightRef} src={pinesRight} alt="pines-right" className="absolute -right-10 -bottom-[10px] w-[600px] z-40" />
            <img src={santa} alt="santa" className="absolute left-1/2 -translate-x-1/2 -bottom-[20px] w-[700px]  z-50" />
            <div className="absolute left-1/2 -translate-x-1/2 bottom-[30%] w-[1400px]  flex items-center justify-between z-40">
                <div className="pl-20 text-[13px] roboto-slab relative z-20  w-[300px] text-center">
                    A short, sweet message can go a long way. Let them know you appreciate them or wish them a good day.
                </div>

                <div className="pr-20 text-[24px] roboto-slab  relative z-20 w-[300px] text-center leading-[30px]">Send messages to your loved ones.</div>
            </div>

            <div className="bg-gradient-to-t from-red-700 to-red-600 animate-gradient absolute left-1/2 -translate-x-1/2 bottom-[13%] z-50 text-center rounded-4xl min-w-[380px] min-h-[160px] flex flex-col items-center justify-center leading-[48px] shadow-2xl shadow-black border-4 border-red-700/50 transition-all duration-300">
                {!sending && <img src={snowflakeWhite} alt="snowflake-white" className="absolute -right-5  -top-[40px] w-[100px]  z-30" />}
                <div className="relative z-20 overflow-hidden p-10 rounded-4xl ">
                    {sending && (
                        <div className="absolute left-0 top-0 w-full h-full bg-red-700/50 backdrop-blur-md z-50 flex flex-col items-center justify-center">
                            <img src={snowflakeWhite} alt="snowflake-white" className="w-[100px] z-30  origin-center animate-pulse  " />

                            <div className="text-[16px] roboto-slab relative z-20 text-center text-white leading-[24px] pt-6 pb-3 loading">Sending message</div>
                        </div>
                    )}
                    {isStep === 1 && (
                        <div
                            className="cursor-pointer flex flex-col gap-4 slideFromBot hover:scale-105 transition-all duration-300"
                            onClick={() => setIsStep(2)}
                        >
                            <div className="text-[34px] font-light grand-hotel relative z-10 text-white drop-shadow-md">Send a message</div>
                            <div className="text-[70px] grand-hotel relative z-20 text-white text-nowrap drop-shadow-md">From Santa</div>
                        </div>
                    )}

                    {isStep === 2 && (
                        <div className="flex flex-col gap-4 roboto-slab slideFromBot transition-all duration-300 relative overflow-hidden min-w-[500px]">
                            {errorMsg && (
                                <div className="absolute top-5 left-0 w-full">
                                    <div className="relative z-20 text-red-500 text-[12px] roboto-slab text-center text-white leading-[14px] bg-red-800 p-3 rounded-xl">
                                        {errorMsg}
                                    </div>
                                </div>
                            )}

                            {showGenerateMessage ? (
                                <GenerateMessage setShowGenerateMessage={setShowGenerateMessage} messageObj={messageObj} setMessageObj={setMessageObj} />
                            ) : (
                                <>
                                    <div className="text-[16px] roboto-slab relative z-20 text-center text-white leading-[24px] pt-6 pb-3">
                                        Remember to include the your email and recipient's email and a merry message!
                                    </div>

                                    <div className="text-white p-6 border bg-red-800/50 border-red-800/80 rounded-2xl leading-[12px] flex gap-2">
                                        <div className="text-[14px] roboto-slab relative z-20 text-center text-white leading-[20px] text-nowrap">From : </div>
                                        <input
                                            type="text"
                                            placeholder="Add your email"
                                            className="w-full  text-[14px] !p-0 placeholder:text-white/70 !leading-[12px]"
                                            onChange={(e) => {
                                                setErrorMsg(null);
                                                setMessageObj({ ...messageObj, from: e.target.value });
                                            }}
                                            onBlur={(e) => {
                                                const value = e.target.value;
                                                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                                if (!emailRegex.test(value) && value !== "") {
                                                    // Handle invalid email (e.g., show error message)
                                                    setErrorMsg("Please enter a valid email address");
                                                } else {
                                                    setErrorMsg(null);
                                                }
                                            }}
                                            value={messageObj.from}
                                        />
                                    </div>
                                    <div className="text-white p-6 border bg-red-800/50 border-red-800/80 rounded-2xl leading-[12px] flex gap-2">
                                        <div className="text-[14px] roboto-slab relative z-20 text-center text-white leading-[20px] text-nowrap">To : </div>
                                        <input
                                            type="text"
                                            placeholder="Add recipient's email"
                                            className="w-full  text-[14px] !p-0 placeholder:text-white/70 !leading-[12px]"
                                            onChange={(e) => {
                                                setErrorMsg(null);
                                                setMessageObj({ ...messageObj, to: e.target.value });
                                            }}
                                            onBlur={(e) => {
                                                const value = e.target.value;
                                                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                                if (!emailRegex.test(value) && value !== "") {
                                                    // Handle invalid email (e.g., show error message)
                                                    setErrorMsg("Please enter a valid email address");
                                                } else {
                                                    setErrorMsg(null);
                                                }
                                            }}
                                            value={messageObj.to}
                                        />
                                    </div>

                                    <div className="text-white p-6 bg-red-800/50 border-red-800/80 border rounded-2xl">
                                        <textarea
                                            value={messageObj.text}
                                            type="text"
                                            rows={3}
                                            placeholder="Message"
                                            className="w-full text-[14px] !p-0 placeholder:text-white/70 leading-[20px] min-h-[100px]"
                                            onChange={(e) => setMessageObj({ ...messageObj, text: e.target.value })}
                                        />
                                        <div className="flex">
                                            <div
                                                className="bg-white text-black px-2 !py-2 pr-6 rounded-4xl cursor-pointer hover:translate-y-[2px] transition-all outline-none leading-[14px] flex items-center gap-3"
                                                onClick={() => setShowGenerateMessage(true)}
                                            >
                                                <div className="w-10 h-10 rounded-full bg-red-700 relative z-20 overflow-hidden">
                                                    <img src={santaThumb} alt="santa-thumb" className="w-full h-full object-cover" />
                                                </div>
                                                Let santa write your message
                                            </div>
                                        </div>
                                    </div>
                                    {!sending ? (
                                        <div className="flex flex-col gap-2">
                                            <button
                                                disabled={!messageObj.from === "" || !messageObj.to === "" || !messageObj.text === ""}
                                                className={`bg-gradient-to-t from-teal-600 to-teal-500 text-white px-4 py-0 rounded-2xl text-[14px] transition-all  outline-none ${
                                                    messageObj.from === "" || messageObj.to === "" || messageObj.text === ""
                                                        ? "opacity-50 cursor-not-allowed"
                                                        : "hover:translate-y-[2px] "
                                                }`}
                                                onClick={handleSubmit}
                                            >
                                                Send Message
                                            </button>
                                            <div
                                                className="text-[12px] roboto-slab relative z-20 text-center text-white cursor-pointer leading-[20px]"
                                                onClick={() => setIsStep(1)}
                                            >
                                                Cancel
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col gap-2">
                                            <div className="text-[12px] roboto-slab relative z-20 w-[300px] text-center text-white cursor-pointer leading-[20px] loading">
                                                Sending
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}

                    {isStep === 3 && (
                        <div className="flex flex-col gap-4">
                            <div className="text-[60px] grand-hotel relative z-20 w-[300px] text-center text-white leading-[60px]">
                                Message sent
                            </div>
                            <div className="text-[12px] roboto-slab relative z-20 w-[300px] text-center text-white cursor-pointer leading-[20px]">
                                    Your message has been sent successfully! to <span className="opacity-60">{messageObj.to}</span> Wishing you a joyful and
                                magical day filled with holiday cheer!
                            </div>

                            <div
                                className=" rounded-2xl  bg-gradient-to-t from-teal-600 to-teal-500 text-white px-5 py-2  text-[14px] mt-5 roboto-slab relative z-20 text-center text-white cursor-pointer leading-[20px]"
                                onClick={() => setIsStep(1)}
                            >
                                Send another message?
                            </div>
                            {thevideo && thevideo.includes("https://") && (
                                <div className="text-[12px] roboto-slab relative z-20 w-[300px] text-center text-white cursor-pointer leading-[20px]">
                                    <a href={thevideo} target="_blank" rel="noopener noreferrer">
                                        <button className="bg-gradient-to-t from-teal-600 to-teal-500 text-white px-5 py-2 rounded-2xl text-[16px] hover:translate-y-[2px] transition-all  outline-none">
                                            View Video
                                        </button>
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Santa;
